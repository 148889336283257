angular.module('app.controllers')
    .controller('GaleriaBibliotecaListController',
        ['$scope', 'modalService', '$http', '$window', 'grideService', 'appConfig',
            function ($scope, modalService, $http, $window, grideService, appConfig) {

                // alert("Funcionando");

                $(".btn-box-tool-cnec").trigger("click");

                //Definições Globais da Controller
                $scope.titleModal = 'Galeria';
                $scope.headerModalDelete = 'Excluir Galeria';
                $scope.titleModalDeleteConfirmation = 'Confirma a Exclusão da Galeria?';

                $scope.galeria = function ($id) {
                    $window.location.href = appConfig.baseUrl + '/administrativo/biblioteca/galeria/' + $id +  '/fotos';
                };

                var buttonAction = '<button tooltip-append-to-body="true" uib-tooltip="Galeria" tooltip-placement="bottom-left" class="btn btn-xs btn-info" ng-click="grid.appScope.galeria(row.entity.id); $event.stopPropagation();">Fotos</button>';


                var columnDefs = [
                    {field: 'titulo_evento', displayName: 'Evento', minWidth: 30, width: '*'},
                    {field: 'historico', displayName: 'Histórico', minWidth: 30, width: '*'},
                    {field: 'dt_evento', displayName: 'Data Evento', minWidth: 30, width: '*'},
                    {
                        name: 'Menu',
                        field: 'Menu',
                        displayName: 'Menu',
                        enableFiltering: false,
                        enableSorting: false,
                        width: 100,
                        cellTemplate: buttonAction
                    },
                    // {
                    //     name: 'Menu',
                    //     field: 'Menu',
                    //     displayName: 'Menu',
                    //     enableFiltering: false,
                    //     enableSorting: false,
                    //     width: 200,
                    //     cellTemplate: buttonAction
                    // }
                ];

                $scope.gridOptions = grideService.montaGrideOptions($scope, '/administrativo/biblioteca/galeria/', columnDefs, '/administrativo/biblioteca/galeria/lista/json', 15);

            }]);
