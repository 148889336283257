angular.module('app.controllers')
        .controller('CurriculoListController',
                ['$scope', 'modalService', '$http', '$window', 'grideService', 'appConfig', function ($scope, modalService, $http, $window, grideService, appConfig) {

                        //Definições Globais da Controller
                        $scope.titleModal = 'Currículo';
                        $scope.headerModalDelete = 'Excluir Currículo';
                        $scope.titleModalDeleteConfirmation = 'Confirma a exclusão do currículo?';

                        var filterOptionsDefault = {};
                        filterOptionsDefault.habilitacao = $window.acad.habilitacao_id;

                        //Botões customizados
                        var buttonAction = '<button uib-tooltip="Currículo Pleno" tooltip-placement="bottom-right" ng-show="row.entity.CanListCurriculoPleno == true" class="btn btn-xs btn-primary" ng-click="grid.appScope.curriculoPlenoAction(row.entity.id); $event.stopPropagation();"><span class="glyphicon glyphicon-book"></span></button>  ';
                        buttonAction += '<button uib-tooltip="Unidades" tooltip-placement="bottom-right" ng-show="row.entity.CanListUnidadeCurriculo == true" class="btn btn-xs btn-primary" ng-click="grid.appScope.unidadeCurriculoAction(row.entity.id); $event.stopPropagation();"><i class="fa fa-university"></i></button>  ';

                        //Ações dos botões customizados
                        $scope.curriculoPlenoAction = function ($id) {
                            $window.location.href = appConfig.baseUrl + '/academico/curriculo-pleno/curriculo/' + $id;
                        };

                        $scope.unidadeCurriculoAction = function ($id) {
                            $window.location.href = appConfig.baseUrl + '/academico/unidade-curriculo/curriculo/' + $id;
                        };

                        var columnDefs = [
                            {field: 'tx_nome', displayName: 'Nome', minWidth: 150, width: '*'},
                            {
                                name: 'Menu',
                                field: 'Menu',
                                displayName: 'Menu',
                                enableFiltering: false,
                                enableSorting: false,
                                width: 140,
                                cellTemplate: buttonAction
                            }
                        ];

                        $scope.gridOptions = grideService.montaGrideOptions($scope, '/academico/curriculo/', columnDefs, '/academico/curriculo/lista/json', 15, true, null, 140, filterOptionsDefault);

                    }]);
