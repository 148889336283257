angular.module('app.controllers')
    .controller('VideoBibliotecaListController',
        ['$scope', 'modalService', '$http', '$window', 'grideService', 'appConfig',
            function ($scope, modalService, $http, $window, grideService, appConfig) {

                // alert("Funcionando");

                $(".btn-box-tool-cnec").trigger("click");

                //Definições Globais da Controller
                $scope.titleModal = 'Vídeo';
                $scope.headerModalDelete = 'Excluir Vídeo';
                $scope.titleModalDeleteConfirmation = 'Confirma a Exclusão do Vídeo?';

                $scope.video = function ($id) {
                    $window.location.href = appConfig.baseUrl + '/administrativo/biblioteca/video/visualizar' + '/' + $id;
                };

                var buttonAction = '<button tooltip-append-to-body="true" uib-tooltip="Vídeo" tooltip-placement="bottom-left" class="btn btn-xs btn-info" ng-click="grid.appScope.video(row.entity.id); $event.stopPropagation();">Vídeo</button>';


                var columnDefs = [
                    {field: 'num_video', displayName: 'Número', minWidth: 30, width: 100},
                    {field: 'titulo', displayName: 'Título', minWidth: 30, width: '*'},
                    {field: 'nome_evento', displayName: 'Nome Evento', minWidth: 30, width: '*'},
                    {field: 'dt_evento', displayName: 'Data', minWidth: 150, width: '*'},
                    {
                        name: 'Menu',
                        field: 'Menu',
                        displayName: 'Menu',
                        enableFiltering: false,
                        enableSorting: false,
                        width: 100,
                        cellTemplate: buttonAction
                    }
                    // {
                    //     name: 'Menu',
                    //     field: 'Menu',
                    //     displayName: 'Menu',
                    //     enableFiltering: false,
                    //     enableSorting: false,
                    //     width: 200,
                    //     cellTemplate: buttonAction
                    // }
                ];

                $scope.gridOptions = grideService.montaGrideOptions($scope, '/administrativo/biblioteca/video/', columnDefs, '/administrativo/biblioteca/video/lista/json', 15);

            }]);
