angular.module('app.controllers')
    .controller('ClassificacaoEditalListController',
        ['$scope', 'modalService', '$http', '$window', 'grideService', 'appConfig', 'Beneficio', '$cookies', '$timeout',
            function ($scope, modalService, $http, $window, grideService, appConfig, Beneficio, $cookies, $timeout) {

                $scope.estados = Beneficio.getComboEstado();

                $(".btn-box-tool-cnec").trigger("click");

                $scope.filterOptions = {};
                $scope.filterOptions.isCeas = false;
                $scope.filterOptions.unidadeFilter = '';
                $scope.filterOptions.txSiglaEstadoFilter = '';
                $scope.unidadeFilter = '';

                //var tipoProcesso = $scope.filterOptions.tipoProcessoFilter;
                var tipoProcesso = $('input[name="tipo-processo"]:checked').length > 0;
                $scope.filterOptions.tipoProcessoFilter = tipoProcesso;

                //if (!tipoProcesso) {
                    //if (!$(this).prop('checked')) {
                    if (!tipoProcesso) {
                        $("#tp_lista option[value='PRE']").remove();
                        $("#tp_lista option[value='LISESPING']").remove();
                        $("#tp_lista option[value='LISFIMING']").remove();
                    } else {
                        if (!$('#tp_lista').find('option[value="PRE"]').length > 0) {
                            $('#tp_lista').append('<option value="PRE">Lista Pré-Seleção</option>');
                        }
                        if (!$('#tp_lista').find('option[value="LISESPING"]').length > 0) {
                            $('#tp_lista').append('<option value="LISESPING">Lista de Espera</option>');
                        }
                        if (!$('#tp_lista').find('option[value="LISFIMING"]').length > 0) {
                            $('#tp_lista').append('<option value="LISFIMING">Lista Final</option>');
                        }
                    }
               // }

                $('#tipoProcessoFilter').change(function () {
                    $scope.filterOptions.tipoProcessoFilter = $(this).prop('checked');
                    $scope.editalFunction();
                    if (!$(this).prop('checked')) {
                        $("#tp_lista option[value='PRE']").remove();
                        $("#tp_lista option[value='LISESPING']").remove();
                        $("#tp_lista option[value='LISFIMING']").remove();
                        if (!$('#tp_lista').find('option[value="LISSELREN"]').length > 0) {
                            $('#tp_lista').append('<option value="LISSELREN">Lista Seleção Renovação</option>');
                        }
                        if (!$('#tp_lista').find('option[value="LISFIMREN"]').length > 0) {
                            $('#tp_lista').append('<option value="LISFIMREN">Lista Final Renovação</option>');
                        }
                    } else {
                        $("#tp_lista option[value='LISSELREN']").remove();
                        $("#tp_lista option[value='LISFIMREN']").remove();
                        if (!$('#tp_lista').find('option[value="PRE"]').length > 0) {
                            $('#tp_lista').append('<option value="PRE">Lista Pré-Seleção</option>');
                        }
                        if (!$('#tp_lista').find('option[value="LISESPING"]').length > 0) {
                            $('#tp_lista').append('<option value="LISESPING">Lista de Espera</option>');
                        }
                        if (!$('#tp_lista').find('option[value="LISFIMING"]').length > 0) {
                            $('#tp_lista').append('<option value="LISFIMING">Lista Final</option>');
                        }
                    }
                })

                $('#prouniFilter').change(function() {
                    $scope.filterOptions.prouniFilter = $(this).prop('checked');
                    $scope.editalFunction();
                })

                //definições globais da controller
                /**
                 * toastr default options
                 */
                toastr.options = {
                    "closeButton": true,
                    "debug": false,
                    "newestOnTop": false,
                    "progressBar": false,
                    "positionClass": "toast-top-center",
                    "preventDuplicates": true,
                    "onclick": null,
                    "showDuration": "1000",
                    "hideDuration": "1000",
                    "timeOut": "5000",
                    "extendedTimeOut": "1000",
                    "showEasing": "swing",
                    "hideEasing": "linear",
                    "showMethod": "fadeIn",
                    "hideMethod": "fadeOut"
                }

                $scope.ufUnidadeFunction = function () {
                    $scope.unidadeFilter = '';
                    $scope.unidades = [];
                    if ($scope.filterOptions.txSiglaEstadoFilter !== undefined && $scope.filterOptions.txSiglaEstadoFilter !== '') {
                        //ajax query
                        $http({
                            method: 'GET',
                            url: appConfig.baseUrl + '/beneficio/listaUnidadeACad/' + $scope.filterOptions.txSiglaEstadoFilter,
                        }).then(function successCallback(response) {
                            $('#divCeas').hide();
                            $scope.unidades = response.data;
                        }, function errorCallback(response) {
                            return response;
                        });
                    }
                }

                $scope.unidadeFunction = function () {
                    $scope.filterOptions.unidadeFilter = $scope.unidadeFilter;
                    //if ($scope.filterOptions.unidadeFilter !== undefined && $scope.filterOptions.unidadeFilter !== '') {
                    if ($scope.unidadeFilter !== undefined && $scope.unidadeFilter !== '') {
                        //ajax verificando se unidade é CEAS para display colunas CEAS
                        $.ajax({
                            url: '/beneficio/verificarCeas',
                            data: {id: $scope.unidadeFilter},
                            type: 'GET',
                            global: false,
                            success: function (response) {
                                if (response.isCeas) {
                                    $scope.filterOptions.isCeas = true;
                                    $('#divCeas').show();
                                } else {
                                    $('#divCeas').hide();
                                    $scope.filterOptions.isCeas = false;
                                }
                            }
                        });

                        //preenche o campo ensino
                        $http({
                            method: 'GET',
                            url: appConfig.baseUrl + '/beneficio/lista-ensino-unidade',
                            params: {id: $scope.unidadeFilter}
                        }).then(function successCallback(response) {
                            $scope.tiposEduca = '';
                            $scope.tiposEduca = response.data.ensino;
                        }, function errorCallback(response) {
                            return response;
                        });
                    }

                }

                $scope.editalFunction = function () {
                    var tp_bolsa = $scope.filterOptions.prouniFilter;
                    var tp_edital = $scope.filterOptions.tipoProcessoFilter;
                    var id_unidade = $scope.filterOptions.unidadeFilter;
                    var tx_sigla_uf = $scope.filterOptions.txSiglaEstadoFilter;
                    var tp_educacao = $scope.filterOptions.tipoEducacaoFilter;

                    var bl_prouni = false;
                    var bl_cnec = false;

                    if (tp_edital == true) {
                        tp_edital = 'I';
                    } else {
                        tp_edital = 'R';
                    }

                    if (tp_bolsa == true) {
                        bl_prouni = true;
                        bl_cnec = false;
                    } else {
                        bl_prouni = false;
                        bl_cnec = true;
                    }

                    if (tp_edital !== '' && tp_edital != null &&
                        tp_educacao !== '' && tp_educacao != null &&
                        tx_sigla_uf !== '' && tx_sigla_uf != null &&
                        id_unidade !== '' && id_unidade != null) {
                        //preenche o campo edital
                        $http({
                            method: 'GET',
                            url: appConfig.baseUrl + '/beneficio/lista-edital-ativo',
                            params: {
                                bl_cnec: bl_cnec,
                                bl_prouni: bl_prouni,
                                tp_edital: tp_edital,
                                tp_educacao: tp_educacao,
                                tx_sigla_uf: tx_sigla_uf,
                                id_unidade: id_unidade
                            }
                        }).then(function successCallback(response) {
                            $scope.editais = '';
                            $scope.editais = response.data.editais;
                        }, function errorCallback(response) {
                            return response;
                        });
                    }
                }

                $scope.mostraGride = function () {
                    $scope.mostraNovo = true;
                    $("#divGride").css({'display': "block"});
                    $scope.processForm();
                }

                $scope.escondeGride = function () {
                    $("#divGride").css({'display': "none"});
                    $scope.filterOptions = {};
                    $scope.filterOptions.unidadeFilter = '';
                    $scope.filterOptions.txSiglaEstadoFilter = '';
                    $scope.filterOptions.tipoProcessoFilter = '';
                    $scope.filterOptions.tipoEducacaoFilter = '';
                    $scope.unidadeFilter = '';
                    $scope.unidades = [];
                    filterOptionsSearch = {};
                    delete filterOptionsSearch;
                    $cookies.remove("searchFilterLista");
                }

                var columnDefs = [
                    {field: 'ordem', displayName: 'Nº', enableSorting: false},
                    {field: 'bolsa', displayName: 'Bolsa Recomendada', enableSorting: false},
                    {field: 'nr_nota_ic', displayName: 'Índice de Classificação'},
                    {field: 'tx_nome_completo', displayName: 'Nome do candidato'},
                    {field: 'tx_nome', displayName: 'Curso'},
                    {field: 'tx_fase_processo', displayName: 'Status'},
                ];

                $scope.arrFaseProcesso = {2 : "Deferido",
                                          4 : "Deferido com recurso",
                                          13 : "Deferido por decisão",
                                          3 : "Indeferido",
                                          5 : "Indeferido com recurso",
                                          11 : "Indeferido por renda",
                                          14 : "Indeferido por decisão"};
                $scope.fases = [];
                angular.forEach($scope.arrFaseProcesso, function (label, value) {
                    this.push({value: value, label: label});
                }, $scope.fases);

                $scope.arrSerieCeas = {1 : 1, 2 : 2, 3 : 3, 4 : 4, 5 : 5, 6 : 6, 7: 7, 8: 8, 9: 9};
                $scope.series = [];
                angular.forEach($scope.arrSerieCeas, function (label, value) {
                    this.push({value: value, label: label});
                }, $scope.series);

                //process gera lista cookies
                $scope.processForm = function() {
                    var today = new Date();
                    var expiresValue = new Date(today);
                    //Set 'expires' option in 2 hours
                    expiresValue.setMinutes(today.getMinutes() + 120);
                    $cookies.put('searchFilterLista', JSON.stringify($scope.filterOptions), expiresValue);
                };

                // cookies para filtro de pesquisa
                var filterOptionsSearch = ($cookies.get('searchFilterLista') !== undefined) ? JSON.parse($cookies.get('searchFilterLista')) : null;

                if (angular.isObject(filterOptionsSearch)) {

                    if (isset(filterOptionsSearch.prouniFilter) && filterOptionsSearch.prouniFilter !== undefined) {
                        $scope.filterOptions.prouniFilter = filterOptionsSearch.prouniFilter;
                        if (filterOptionsSearch.prouniFilter) {
                            $('#prouniFilter').bootstrapToggle('on');
                        } else {
                            $('#prouniFilter').bootstrapToggle('off');
                        }
                    }

                    if (isset(filterOptionsSearch.txSiglaEstadoFilter) && filterOptionsSearch.txSiglaEstadoFilter !== undefined) {
                        $scope.filterOptions.txSiglaEstadoFilter = filterOptionsSearch.txSiglaEstadoFilter;
                        $scope.$watch('filterOptions.txSiglaEstadoFilter', function () {
                            $scope.ufUnidadeFunction();
                        });
                    }

                    if (isset(filterOptionsSearch.unidadeFilter) && filterOptionsSearch.unidadeFilter !== undefined) {
                        $scope.filterOptions.unidadeFilter = filterOptionsSearch.unidadeFilter;
                        $timeout(function () {
                            $scope.unidadeFilter = filterOptionsSearch.unidadeFilter;
                            $scope.unidadeFunction();
                        }, 300);
                    }

                    if (isset(filterOptionsSearch.tipoEducacaoFilter) && filterOptionsSearch.tipoEducacaoFilter !== undefined) {
                        $scope.filterOptions.tipoEducacaoFilter = filterOptionsSearch.tipoEducacaoFilter;
                        $timeout(function () {
                            $scope.filterOptions.tipoEducacaoFilter = filterOptionsSearch.tipoEducacaoFilter;
                        }, 300);
                    }

                    if (isset(filterOptionsSearch.tipoProcessoFilter) && filterOptionsSearch.tipoProcessoFilter !== undefined) {
                        $scope.filterOptions.tipoProcessoFilter = filterOptionsSearch.tipoProcessoFilter;
                        if (filterOptionsSearch.tipoProcessoFilter) {
                            $timeout(function () {
                                $('#tipoProcessoFilter').bootstrapToggle('on');
                            }, 300);
                        } else {
                            $timeout(function () {
                                $('#tipoProcessoFilter').bootstrapToggle('off');
                            }, 300);
                        }
                    }

                    if (isset(filterOptionsSearch.editalFilter) && filterOptionsSearch.editalFilter !== undefined) {
                        $scope.filterOptions.editalFilter = filterOptionsSearch.editalFilter;
                        $timeout(function () {
                            $scope.filterOptions.editalFilter = filterOptionsSearch.editalFilter;
                        }, 300);
                    }

                    if (isset(filterOptionsSearch.faseProcessoFilter) && filterOptionsSearch.faseProcessoFilter !== undefined) {
                        $scope.filterOptions.faseProcessoFilter = filterOptionsSearch.faseProcessoFilter;
                    }

                    //CEAS
                    if (isset(filterOptionsSearch.tipoPeriodoFilter) && filterOptionsSearch.tipoPeriodoFilter !== undefined) {
                        $scope.filterOptions.tipoPeriodoFilter = filterOptionsSearch.tipoPeriodoFilter;
                        if (filterOptionsSearch.tipoPeriodoFilter) {
                            $("input[name=tipoPeriodoFilter][value='true']").prop("checked",true);
                        } else {
                            $("input[name=tipoPeriodoFilter][value='false']").prop("checked",true);
                        }
                    }

                    if (isset(filterOptionsSearch.nuSeriePsFilter) && filterOptionsSearch.nuSeriePsFilter !== undefined) {
                        $scope.filterOptions.nuSeriePsFilter = filterOptionsSearch.nuSeriePsFilter;
                    }

                    $timeout(function () {
                        angular.element('#pesquisar').trigger('click');
                    }, 100);

                }

                $scope.gridOptions = grideService.montaGrideOptions($scope, '/beneficio/getListaClassificacao/', columnDefs, '/beneficio/getListaClassificacao', 15, false, null, 100, null, false);
            }]);