angular.module('app.controllers').controller('UserListController', ['$scope', '$http', '$window', 'grideService', 'User', 'appConfig',
    function ($scope, $http, $window, grideService, User, appConfig) {
        /**
         * [filterOptions autocomplete email]
         * @type {Object}
         */
        $scope.filterOptions = {};
        $scope.filterOptions.email = "";
        $scope.emails = User.getListaEmailUsuario();
        //definições globais da controller
        $scope.titleModal = 'Usuário';
        $scope.headerModalDelete = 'Excluir Usuário';
        $scope.titleModalDeleteConfirmation = 'Confirma a exclusão do usuário?';

        /**
         * [inicio chamada da gride]
         * @type {gridOptions}
         */
        var columnDefs = [
            {
                field: 'name',
                displayName: 'Nome',
                minWidth: 150,
                width: '*',
                cellTooltip: function (row, col) {
                    return 'Nome: ' + row.entity.name + ', E-mail: ' + row.entity.email;
                }
            }, {
                field: 'email',
                displayName: 'E-mail',
                minWidth: 150,
                width: '*'
            }, {
                field: 'celular',
                displayName: 'Celular',
                minWidth: 150,
                width: '*'
            }, {
                field: 'nucleo_nome',
                displayName: 'Núcleo',
                minWidth: 150,
                width: '*'
            }];

        //chamada grid factory
        $scope.gridOptions = grideService.montaGrideOptions($scope, '/seguranca/users/', columnDefs, '/seguranca/listaUsuario/', 15);
    }
]);