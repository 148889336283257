angular.module('app.services')
    .service('Beneficio', ['$resource', 'appConfig', function ($resource, appConfig) {
        return $resource(appConfig.baseUrl + '/beneficio/', {},
            {
                getComboUnidade: {
                    method: 'GET',
                    isArray: true,
                    url: 'listaUnidadePerseus',
                },
                getComboEstado: {
                    method: 'GET',
                    isArray: false,
                    url: 'listaEstado',
                },
                getComboEdital: {
                    method: 'GET',
                    isArray: false,
                    url: 'listaEdital',
                },
                getComboFaseProcesso: {
                    method: 'GET',
                    isArray: false,
                    url: 'listaFaseProcesso',
                }
            });
    }]);