angular.module('app.controllers')
        .controller('BeneficioListController',
                ['$scope', 'modalService', '$http', '$window', 'grideService', 'appConfig', 'Beneficio', '$timeout', '$cookies', '$modal',
                    function ($scope, modalService, $http, $window, grideService, appConfig, Beneficio, $timeout, $cookies, $modal) {

                        $(".btn-box-tool-cnec").trigger("click");

                        //filtro default sistema
                        $('#sistemaFilter').bootstrapToggle('on');
                        $scope.sistemaFilter = true;
                        $scope.meusProcessos = false;
                        $scope.processosPendentes = false;

                        $scope.mostraNovo = true;

                        /**
                         *
                         * INIT vars default
                         */
                        $scope.filterOptions = {};
                        $scope.filterOptions.cursoUnidadeFilter = '';
                        $scope.filterOptions.unidadeFilter = '';
                        $scope.filterOptions.txSiglaEstadoFilter = '';

                        $scope.unidadeFilter = '';
                        $scope.cursoUnidadeFilter = '';
                        $scope.cursoUnidades = [];
                        $scope.unidades = [];
                        $scope.estados = Beneficio.getComboEstado();
                        $scope.editais = Beneficio.getComboEdital();
                        $scope.fases = Beneficio.getComboFaseProcesso();

                        $scope.arrTipoMat = {'FOR': 'FORMADO',
                                             'FRM': 'FORMANDO',
                                             'JUB': 'JUBILADO',
                                             'MPF': 'MATRÍCULADO COM PEDÊNCIA',
                                             'NAO': 'NÃO MATRICULADO',
                                             'NAP': 'NÃO APTO',
                                             'NMW': 'NÃO MATRICULA WEB',
                                             'PEA': 'PRÉ-MATRÍCULA EAD',
                                             'PMA': 'PRÉ-MATRÍCULA',
                                             'PP': 'APROVADO EM PROGRESSÃO PARCIAL',
                                             'RCU': 'REMANEJADO DE CURSO',
                                             'REP': 'REPROVADO',
                                             'RTU': 'REMANEJADO DE TURMA',
                                             'TCR': 'TRANSFERIDO DE CURRÍCULO',
                                             'TEE': 'TERMINALIDADE ESPECÍFICA',
                                             'TR1': 'TRANSFERIDO DE TURNO',
                                             'TRA': 'TRANCADO',
                                             'TRC': 'TRANSFERIDO DE CURSO',
                                             'TRE': 'TRANSFERIDO DE ESCOLA',
                                             'TRF': 'TRANSFERIDO',
                                             'TRI': 'TRANSFERIDO DE IES',
                                             'TRO': 'TRANSFERIDO EX-OFFICIO',
                                             'TRP': 'TRANSFERÊNCIA DE POLO',
                                             'TRT': 'TRANSFERIDO DE TURMA'};
                        $scope.matriculas = [];
                        angular.forEach($scope.arrTipoMat, function (label, value) {
                            this.push({value: value, label: label});
                        }, $scope.matriculas);

                        var filterOptionsSearch = ($cookies.get('searchFilter') !== undefined) ? JSON.parse($cookies.get('searchFilter')) : null;
                        var filterOptionsSearchEdital = ($cookies.get('searchFilterEdital') !== undefined) ? JSON.parse($cookies.get('searchFilterEdital')) : null;

                        // nova ficha vindo do pesquisar edital de renovação
                        if (angular.isObject(filterOptionsSearchEdital) && !angular.isObject(filterOptionsSearch)) {
                            if (isset(filterOptionsSearchEdital.txSiglaEstadoFilter) && filterOptionsSearchEdital.txSiglaEstadoFilter !== undefined) {
                                $scope.filterOptions.txSiglaEstadoFilter = filterOptionsSearchEdital.txSiglaEstadoFilter;
                                $scope.$watch('filterOptions.txSiglaEstadoFilter', function () {
                                    $scope.ufUnidadeFunction();
                                });
                            }

                            if (isset(filterOptionsSearchEdital.unidadeFilter) && filterOptionsSearchEdital.unidadeFilter !== undefined) {
                                $scope.filterOptions.unidadeFilter = filterOptionsSearchEdital.unidadeFilter;
                                $timeout(function () {
                                    $scope.unidadeFilter = filterOptionsSearchEdital.unidadeFilter;
                                    $scope.unidadeFunction();
                                }, 300);
                            }

                            $('#sistemaFilter').val('off').trigger("change");
                            $('#sistemaFilter').bootstrapToggle('off');
                            $scope.sistemaFilter = false;
                            $scope.filterOptions.sistemaFilter = false;

                            $timeout(function () {
                                angular.element('#pesquisar').trigger('click');
                            }, 100);
                        }

                        if (angular.isObject(filterOptionsSearch) && !angular.isObject(filterOptionsSearchEdital)) {
                            if (isset(filterOptionsSearch.txSiglaEstadoFilter) && filterOptionsSearch.txSiglaEstadoFilter !== undefined) {
                                $scope.filterOptions.txSiglaEstadoFilter = filterOptionsSearch.txSiglaEstadoFilter;
                                $scope.$watch('filterOptions.txSiglaEstadoFilter', function () {
                                    $scope.ufUnidadeFunction();
                                });
                            }

                            if (isset(filterOptionsSearch.unidadeFilter) && filterOptionsSearch.unidadeFilter !== undefined) {
                                $scope.filterOptions.unidadeFilter = filterOptionsSearch.unidadeFilter;
                                $timeout(function () {
                                    $scope.unidadeFilter = filterOptionsSearch.unidadeFilter;
                                    $scope.unidadeFunction();
                                }, 300);
                            }

                            if (isset(filterOptionsSearch.cursoUnidadeFilter) && filterOptionsSearch.cursoUnidadeFilter !== undefined) {
                                $scope.filterOptions.cursoUnidadeFilter = filterOptionsSearch.cursoUnidadeFilter;
                                $scope.cursoUnidadeFilter = filterOptionsSearch.cursoUnidadeFilter;
                            }

                            if (isset(filterOptionsSearch.nameFilter) && filterOptionsSearch.nameFilter !== undefined) {
                                $scope.filterOptions.nameFilter = filterOptionsSearch.nameFilter;
                            }

                            if (isset(filterOptionsSearch.sistemaFilter) && filterOptionsSearch.sistemaFilter !== undefined) {
                                $scope.filterOptions.sistemaFilter = filterOptionsSearch.sistemaFilter;
                                if (filterOptionsSearch.sistemaFilter) {
                                    $('#sistemaFilter').bootstrapToggle('on');
                                } else {
                                    $('#sistemaFilter').bootstrapToggle('off');
                                }
                            }

                            if (isset(filterOptionsSearch.meusProcessosFilter) && filterOptionsSearch.meusProcessosFilter !== undefined) {
                                $scope.filterOptions.meusProcessosFilter = filterOptionsSearch.meusProcessosFilter;
                                if (filterOptionsSearch.meusProcessosFilter) {
                                    $('#meusProcessosFilter').bootstrapToggle('on');
                                } else {
                                    $('#meusProcessosFilter').bootstrapToggle('off');
                                }
                            }

                            if (isset(filterOptionsSearch.processoPendenteFilter) && filterOptionsSearch.processoPendenteFilter !== undefined) {
                                $scope.filterOptions.processoPendenteFilter = filterOptionsSearch.processoPendenteFilter;
                                if (filterOptionsSearch.processoPendenteFilter) {
                                    $('#processoPendenteFilter').bootstrapToggle('on');
                                } else {
                                    $('#processoPendenteFilter').bootstrapToggle('off');
                                }
                            }

                            if (isset(filterOptionsSearch.cpfFilter) && filterOptionsSearch.cpfFilter !== undefined) {
                                $scope.filterOptions.cpfFilter = filterOptionsSearch.cpfFilter;
                            }

                            if (isset(filterOptionsSearch.dtNascimentoFilter) && filterOptionsSearch.dtNascimentoFilter !== undefined) {
                                $scope.filterOptions.dtNascimentoFilter = filterOptionsSearch.dtNascimentoFilter;
                            }

                            if (isset(filterOptionsSearch.editalFilter) && filterOptionsSearch.editalFilter !== undefined) {
                                $scope.filterOptions.editalFilter = filterOptionsSearch.editalFilter;
                            }

                            if (isset(filterOptionsSearch.faseProcessoFilter) && filterOptionsSearch.faseProcessoFilter !== undefined) {
                                $scope.filterOptions.faseProcessoFilter = filterOptionsSearch.faseProcessoFilter;
                            }

                            if (isset(filterOptionsSearch.meusProcessosFilter) && filterOptionsSearch.meusProcessosFilter && filterOptionsSearch.meusProcessosFilter !== undefined) {
                                $scope.filterOptions.meusProcessosFilter = filterOptionsSearch.meusProcessosFilter;
                            }

                            if (isset(filterOptionsSearch.processoPendenteFilter) && filterOptionsSearch.processoPendenteFilter && filterOptionsSearch.processoPendenteFilter !== undefined) {
                                $scope.filterOptions.processoPendenteFilter = filterOptionsSearch.processoPendenteFilter;
                            }

                            if (isset(filterOptionsSearch.sitMatriculaFilter) && filterOptionsSearch.sitMatriculaFilter && filterOptionsSearch.sitMatriculaFilter !== undefined) {
                                $scope.filterOptions.sitMatriculaFilter = filterOptionsSearch.sitMatriculaFilter;
                            }

                            $timeout(function () {
                                angular.element('#pesquisar').trigger('click');
                            }, 100);

                        }

                        $scope.ufUnidadeFunction = function () {
                            $scope.unidadeFilter = '';
                            $scope.cursoUnidades = '';
                            $scope.unidades = [];
                            if ($scope.filterOptions.txSiglaEstadoFilter !== undefined && $scope.filterOptions.txSiglaEstadoFilter !== '') {
                                //ajax query
                                $http({
                                    method: 'GET',
                                    cache: true,
                                    url: appConfig.baseUrl + '/beneficio/listaUnidadeACad/' + $scope.filterOptions.txSiglaEstadoFilter,
                                }).then(function successCallback(response) {
                                    $scope.unidades = response.data;
                                }, function errorCallback(response) {
                                    return response;
                                });
                            }
                        }

                        $scope.unidadeFunction = function () {
                            $scope.filterOptions.unidadeFilter = $scope.unidadeFilter;
                            if ($scope.unidadeFilter !== undefined && $scope.unidadeFilter !== '') {
                                //ajax query
                                $http({
                                    method: 'GET',
                                    cache: true,
                                    url: appConfig.baseUrl + '/beneficio/listaCursoByUnidade/' + $scope.unidadeFilter,
                                }).then(function successCallback(response) {
                                    $scope.cursoUnidades = '';
                                    $scope.cursos = [];
                                    angular.forEach(response.data, function (label, value) {
                                        this.push({value: value, label: label});
                                    }, $scope.cursos);
                                    $scope.cursoUnidades = $scope.cursos;
                                }, function errorCallback(response) {
                                    return response;
                                });
                            }
                        }

                        $scope.cursoFunction = function () {
                            $scope.filterOptions.cursoUnidadeFilter = $scope.cursoUnidadeFilter;
                        }

                        $scope.novoProcesso = function () {
                            $window.location.href = appConfig.baseUrl + '/beneficio/dados-preliminares/cadastro';
                        }

                        //Botões customizados
                        var buttonAction = '<button uib-tooltip="Visualizar Ficha" tooltip-placement="bottom-right" class="btn btn-xs btn-primary" ' +
                                'ng-click="grid.appScope.visualizarAction(row.entity.ID_PROCESSO,row.entity.ID_PESSOA_PERSEUS,row.entity.SEDCODIGO,row.entity.CURCODIGO, false); $event.stopPropagation();">' +
                                '<span class="glyphicon glyphicon-list-alt"></span>' +
                                '</button>  ';

                        var buttonUnblock = '<button uib-tooltip="Desbloquear Processo" tooltip-placement="bottom-right" class="btn btn-xs btn-danger" ' +
                                'ng-show="row.entity.HAS_BLOCK;"' +
                                'ng-click="grid.appScope.modalDesbloquear(row.entity.ID_PROCESSO, row.entity.HAS_BLOCK); $event.stopPropagation();">' +
                                '<span class="glyphicon glyphicon-lock"></span>' +
                                '</button>  ';

                        var buttonRenew = '<button uib-tooltip="Novo Processo" tooltip-placement="bottom-right" class="btn btn-xs btn-success" ' +
                                'ng-show="row.entity.FASE_PROCESSO_6"' +
                                'ng-click="grid.appScope.renovarAction(row.entity.ID_PROCESSO, row.entity.ALUPROCESSO, row.entity.ALUNOMECOMPLETO); $event.stopPropagation();">' +
                                '<span class="glyphicon glyphicon-new-window"></span>' +
                                '</button>  ';

                        var buttonAnexo = '<button uib-tooltip="Anexo do Processo" tooltip-placement="bottom-right" class="btn btn-xs btn-primary" ' +
                                'ng-show="row.entity.ID_PROCESSO"' +
                                'ng-click="grid.appScope.showAnexoAction(row.entity.ID_PROCESSO); $event.stopPropagation();">' +
                                '<span class="glyphicon glyphicon-save"></span>' +
                                '</button>  ';

                        var buttonDelete = '<button tooltip-append-to-body="true" uib-tooltip="Excluir" tooltip-placement="bottom-left" ' +
                                'ng-show="row.entity.IDFASEPROCESSO == 1 || row.entity.IDFASEPROCESSO == 10"' +
                                'class="btn btn-xs btn-danger" ' +
                                'ng-click="grid.appScope.excluirAction(row.entity.ID_PROCESSO, row.entity.ALUPROCESSO, row.entity.ALUNOMECOMPLETO, row); $event.stopPropagation();">' +
                                '<span class="glyphicon glyphicon-trash"></span>' +
                                '</button>  ';

                        //Ações dos botões customizados
                        $scope.visualizarAction = function ($idProcesso, $idPessoaPerseus, $idUnidade, $idCurso) {
                            var id;
                            var tipo;
                            if ($idProcesso !== null) {
                                id = $idProcesso;
                                tipo = 'PR';
                            } else {
                                id = $idPessoaPerseus;
                                tipo = 'PS';
                            }
                            //encode base 64
                            var idEncodeBase64 = btoa(id);
                            var idUnidadeEncodeBase64 = btoa($idUnidade);
                            $window.location.href = appConfig.baseUrl + '/beneficio/dados-preliminares/cadastro/' + idEncodeBase64 + '/' + tipo + '/' + idUnidadeEncodeBase64 + '/' + $idCurso;
                        };
                        $scope.modalDesbloquear = function ($idProcesso, hasBlock) {
                            if (!hasBlock) {
                                Cnec.messageBox.information('Esse processo não está bloqueado');
                                return;
                            }
                            var msg = '<p>Você gostaria de desbloquear este processo?</p>';
                            msg += '<div class="form-group">';
                            msg += '<label for="comment">Justificativa:</label>';
                            msg += '<textarea class="form-control required" rows="5" id="justificativa"></textarea>';
                            msg += '<span class="help-block"></span>';
                            msg += '</div>';
                            Cnec.messageBox.question({
                                title: 'Desbloqueio de processo',
                                message: msg,
                                buttons: {
                                    Sim: function () {
                                        if (Core.form.validate($('#justificativa'))) {
                                            Cnec.Ajax().submit({
                                                route: '/beneficio/desbloquear',
                                                type: 'patch',
                                                data: {idProcesso: $idProcesso, justificativa: $('#justificativa').val()},
                                                callbackSuccess: function () {
                                                    $scope.filterGrid($scope.filterOptions);
                                                    $scope.mostraGride();
                                                }
                                            });
                                        } else {
                                            throw new Error("Campo Obrigatório!");
                                        }

                                    },
                                    Não: function () {

                                    }
                                }
                            });

                        };

                        $scope.renovarAction = function (iProcesso, nProcesso, nAluno) {
                            $scope.nProcesso = nProcesso;
                            $scope.nAluno = nAluno;
                            $scope.iProcesso = iProcesso;

                            $http.get('/beneficio/lista-edital', {params: {idProcesso: iProcesso}, cache: true}).then(function (response) {
                                $scope.editalRenovacao = response.data;

                            });
                            $('#modal-renovacao').modal();

                        };

                        $scope.showAnexoAction = function (iProcesso) {
                            $scope.title = 'Visualizar Anexo do Processo';
                            $http({
                                method: 'GET',
                                cache: true,
                                url: appConfig.baseUrl + '/beneficio/listaPesquisaAnexoProcesso/' + iProcesso
                            }).then(function successCallback(response) {
                                $scope.conteudo = response.data.content;
                                $modal({
                                    title: $scope.title,
                                    show: true,
                                    html: true,
                                    id: 'modalViewAnexo',
                                    scope: $scope,
                                    templateUrl: '/../partials/modal-view.html'
                                });
                                $modal.show;
                                $timeout(function () {
                                    $('body').removeClass('modal-open');
                                }, 100);
                            }, function errorCallback(response) {
                                toastr["error"]('Ocorreu um erro ao realizar a operação');
                            });
                        };

                        $scope.confirmarRenovacao = function () {
                            if (!Core.form.validate($('#nr_edital'))) {
                                return false;
                            }
                            var formRenovacao = {
                                id_edital: $scope.formRenovacao.id_edital,
                                id_processo: $scope.iProcesso
                            };
                            $http.post('/beneficio/renovar', formRenovacao).then(function (response) {
                                if (response.data.type == 'success') {
                                    $window.location.href = appConfig.baseUrl + response.data.url;
                                } else {
                                    Cnec.messageBox.critical(response.data.msg);
                                }
                            });
                        };

                        $scope.excluirAction = function (iProcesso, nProcesso, nAluno, $row) {
                            $scope.nProcesso = nProcesso;
                            $scope.nAluno = nAluno;
                            $scope.iProcesso = iProcesso;
                            $scope.row = $row;

                            $('#modal-excluir').modal();

                        };

                        $scope.confirmarExcluir = function () {
                            if (!Core.form.validate($('#tx_justificativa'))) {
                                return false;
                            }
                            var formExcluir = {
                                tx_justificativa: $scope.formExcluir.tx_justificativa,
                                id_processo: $scope.iProcesso
                            };
                            $http.post('/beneficio/excluir', formExcluir).then(function (response) {
                                if (response.data.type == 'success') {
                                    $('#tx_justificativa').val('');
                                    $('#modal-excluir').modal('toggle');
                                    var index = $scope.gridOptions.data.indexOf($scope.row.entity);
                                    $scope.gridOptions.data.splice(index, 1);
                                    toastr["success"](response.data.msg);
                                } else {
                                    toastr["error"](response.data.msg);
                                }
                            });
                        };

                        var columnDefs = [
                            {
                                field: 'ALUNOMECOMPLETO', displayName: 'Nome', minWidth: 250, width: '**'
                            },
                            {
                                field: 'ALUNASCIMENTO', displayName: 'Data de nascimento', minWidth: 180, width: '**'
                            },
                            {
                                field: 'CURNOME', displayName: 'Curso', minWidth: 180, width: '**'
                            },
                            {
                                field: 'ALUCPF', displayName: 'CPF', minWidth: 150, width: '**',
                            },
                            {
                                field: 'ALUPROCESSO', displayName: 'Processo', minWidth: 110, width: '**', enableSorting: false,
                            },
                            {
                                field: 'ALUEDITAL', displayName: 'Edital', minWidth: 90, width: '**', enableSorting: false,
                            },
                            {
                                name: 'Menu',
                                field: 'Menu',
                                displayName: 'Menu',
                                enableFiltering: false,
                                enableSorting: false,
                                width: 140,
                                cellTemplate: buttonAction + buttonUnblock + buttonRenew + buttonAnexo + buttonDelete
                            }
                        ];

                        $scope.mostraGride = function () {
                            //limpa cookies da pesquisa do edital
                            $cookies.remove("searchFilterEdital");
                            $scope.mostraNovo = true;
                            $("#divGride").css({'display': "block"});
                            var today = new Date();
                            var expiresValue = new Date(today);
                            //Set 'expires' option in 2 hours
                            expiresValue.setMinutes(today.getMinutes() + 120);
                            $cookies.put('searchFilter', JSON.stringify($scope.filterOptions), expiresValue);
                        }

                        //filtro para sistema
                        $('#sistemaFilter').change(function() {
                            $scope.sistemaFilter = $(this).prop('checked');
                            $scope.filterOptions.sistemaFilter = $(this).prop('checked');
                        })
                        //filtro para meus processos
                        $('#meusProcessosFilter').change(function() {
                            $scope.meusProcessos = $(this).prop('checked');
                            $scope.filterOptions.meusProcessosFilter = $(this).prop('checked');
                        })
                        //filtro para processos pendentes
                        $('#processoPendenteFilter').change(function() {
                            $scope.processosPendentes = $(this).prop('checked');
                            $scope.filterOptions.processoPendenteFilter = $(this).prop('checked');
                        })

                        $scope.escondeGride = function () {
                            $("#divGride").css({'display': "none"});
                            $scope.mostraNovo = false;
                            $scope.filterOptions = {};
                            $scope.filterOptions.cursoUnidadeFilter = '';
                            $scope.filterOptions.unidadeFilter = '';
                            $scope.filterOptions.txSiglaEstadoFilter = '';
                            $scope.unidadeFilter = '';
                            $scope.cursoUnidadeFilter = '';
                            $scope.cursoUnidades = [];
                            $scope.unidades = [];
                            filterOptionsSearch = {};
                            delete filterOptionsSearch;
                            $cookies.remove("searchFilter");
                            $cookies.remove("searchFilterEdital");
                            $.removeCookie('renovaProcesso');
                            $('#sistemaFilter').bootstrapToggle('on');
                            $('#meusProcessosFilter').bootstrapToggle('off');
                            $('#processoPendenteFilter').bootstrapToggle('off');
                        };

                        $scope.gridOptions = grideService.montaGrideOptions($scope, '/beneficio/', columnDefs, '/beneficio/listaPerseus', 15, false, null, 140, null, false, false);

                    }]);
