angular.module('app.services')
    .service('Pessoa', ['$resource', 'appConfig', function ($resource, appConfig) {
        return $resource(appConfig.baseUrl + '/administrativo/',{},
        {
            update: {
                method: 'GET'
        },
            getListaPessoa: {
                method: 'GET',
                isArray: true,
                url: 'listaEmailUsuario',
            }
        });
    }]);