angular.module('app.services')
    .service('User', ['$resource', 'appConfig', function ($resource, appConfig) {
        return $resource(appConfig.baseUrl + '/seguranca/',{},
        {
            update: {
                method: 'GET'
        },
            getListaEmailUsuario: {
                method: 'GET',
                isArray: true,
                url: 'listaEmailUsuario',
            }
        });
    }]);