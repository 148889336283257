/**
 * @var {object}
 */
Core.console = new (function() {

    /**
     * @returns {boolean}
     */
    var _isIeValid = function() {
        //return ($.browser.msie && parseFloat($.browser.version) >= 9);
        return true;
    };

    /**
     * @returns {boolean}
     */
    var _isNotIe = function() {
        //return (!$.browser.msie);
        return true;
    };

    /**
     * @var {boolean}
     */
    this.enabled = false;

    /**
     * @var object LOG, WARN, INFO, ERROR
     */
    this.type = {
        "LOG": 0x00,
        "WARN": 0x01,
        "INFO": 0x02,
        "ERROR": 0x03
    };

    /**
     * @param {string} debug
     * @param {string} label
     * @param {Core.console.type} type
     * @returns {undefined}
     */
    this.debug = function(debug, label, type)
    {
        if (this.enabled) {
            label = label || "";
            if (_isIeValid() || _isNotIe()) {
                switch (type) {
                    case Core.console.type.ERROR:
                        if (label !== "")
                            console.error(label, ' ', debug);
                        else
                            console.error(debug);
                        break;
                    case Core.console.type.INFO:
                        if (label !== "")
                            console.info(label, ' ', debug);
                        else
                            console.info(debug);
                        break;
                    case Core.console.type.WARN:
                        if (label !== "")
                            console.warn(label, ' ', debug);
                        else
                            console.warn(debug);
                        break;
                    case Core.console.type.LOG:
                    default:
                        if (label !== "")
                            console.log(label, ' ', debug);
                        else
                            console.log(debug);
                        break;
                }
            }
        }

    };

    /**
     * @param {string} label
     * @param {boolean} isCollapsed
     * @returns {undefined}
     */
    this.group = function(label, isCollapsed)
    {
        if (this.enabled) {
            isCollapsed = isCollapsed || false;
            if (_isNotIe()) {
                if (isCollapsed === true) {
                    console.groupCollapsed(label);
                } else {
                    console.group(label);
                }
            } else if (_isIeValid()) {
                console.log("|Start Group: " + label + "|");
            }
        }
    };

    /**
     * @returns {undefined}
     */
    this.groupEnd = function()
    {
        if (this.enabled) {
            if (_isNotIe()) {
                console.groupEnd();
            } else if (_isIeValid()) {
                console.log("|End Group|");
            }
        }
    };
});