angular.module('app.controllers')
        .controller('HabilitacaoListController',
                ['$scope', 'modalService', '$http', '$window', 'grideService', 'appConfig', function ($scope, modalService, $http, $window, grideService, appConfig) {

                        //Definições Globais da Controller
                        $scope.titleModal = 'Habilitação';
                        $scope.headerModalDelete = 'Excluir Habilitação';
                        $scope.titleModalDeleteConfirmation = 'Confirma a exclusão da habilitação?';

                        var filterOptionsDefault = {};
                        filterOptionsDefault.curso = $window.acad.curso_id;

                        //add custom button
                        var buttonAction = '<button uib-tooltip="Currículos" tooltip-placement="bottom-right" ng-show="row.entity.CanListCurriculo == true" class="btn btn-xs btn-primary" ng-click="grid.appScope.curriculoAction(row.entity.id); $event.stopPropagation();"><span class="glyphicon glyphicon-book"></span></button>  ';

                        $scope.curriculoAction = function ($id) {
                            $window.location.href = appConfig.baseUrl + '/academico/curriculo/habilitacao/' + $id;
                        };

                        var columnDefs = [
                            {field: 'tx_sigla', displayName: 'Sigla', minWidth: 150, width: '*'},
                            {field: 'tx_nome', displayName: 'Nome', minWidth: 150, width: '*'},
                            {
                                name: 'Menu',
                                field: 'Menu',
                                displayName: 'Menu',
                                enableFiltering: false,
                                enableSorting: false,
                                width: 140,
                                cellTemplate: buttonAction
                            }
                        ];

                        $scope.gridOptions = grideService.montaGrideOptions($scope, '/academico/habilitacao/', columnDefs, '/academico/habilitacao/lista/json', 15, true, null, 140, filterOptionsDefault);

                    }]);
