
$(document).ready(function () {
    $('#btn-cadastro').click(function () {
        var form = $('#form-exemplo');
        var frontend = $('input[name="frontend"]').is(':checked');
        if (frontend) {
            console.log(form);
            if(Core.form.validate(form)){
                form.submit();  
            }
        }else{
            form.submit();
        }
    });
    
    $('.exemplo .btn-danger').click(function(){
        var id = $(this).attr('id');
        Cnec.messageBox.question({
            message: 'Tem certeza que deseja excluir esse registro?',
            buttons: {
                Cancelar: function(){
                    
                },
                Excluir: function(){
                    Cnec.Ajax().submit({
                       route: '/exemplo/excluir',
                       type: 'delete',
                       data: {id: id},
                       callbackSuccess: function(){
                           location.reload();
                       }
                    });
                }
            }
        });
    });
    
});



