Core.validate = {
    /**
     * @param {mixed} elementOrValue
     * @returns {Boolean}
     */
    "_isObjQuery": function(elementOrValue) {
        return (typeof (elementOrValue) === 'object' && elementOrValue.jquery);
    },
    /**
     * @param {mixed} elementOrValue
     * @returns {string|number}
     */
    "_getValue": function(elementOrValue) {
        var value = '';
        if (Core.validate._isObjQuery(elementOrValue)) {
            value = elementOrValue.val();
        } else {
            if ($.inArray(typeof value, ['string', 'number']) > -1) {
                value = elementOrValue;
            }
        }
        return value;
    },
    /**
     * Verificação de e-mail
     * 
     * @param {mixed} elementOrValue
     * @returns {Boolean}
     */
    "email": function(elementOrValue) {
        var value = Core.validate._getValue(elementOrValue);
        if (value !== '') {

            var isEmail = function(str) {
                var regExp = /^[A-Za-z0-9_\-\.]+@[A-Za-z0-9_\-\.]{2,}\.[A-Za-z0-9]{2,}(\.[A-Za-z0-9])?/;
                if (typeof (str) === "string") {
                    return regExp.test(str);
                } else {
                    return false;
                }
            };
            if (!isEmail(elementOrValue.val())) {
                if (Core.validate._isObjQuery(elementOrValue)) {
                    Core.form.setValidateTootip(elementOrValue, 'E-mail inválido');
                }
                return false;
            }
        }
        return true;
    },
    /**
     * Verificação de inteiros
     * 
     * @param {mixed} elementOrValue
     * @returns {Boolean}
     */
    "integer": function(elementOrValue) {
        var value = Core.validate._getValue(elementOrValue);
        if (value !== '') {

            var isInteger = function(str) {
                return !isNaN(str);
            };
            if (!isInteger(elementOrValue.val())) {
                if (Core.validate._isObjQuery(elementOrValue)) {
                    Core.form.setValidateTootip(elementOrValue, 'Inteiro inválido');
                }
                return false;
            }
        }
        return true;
    },
    /**
     * Verificação de datas
     * 
     * @param {mixed} elementOrValue
     * @returns {Boolean}
     */
    "date": function(elementOrValue) {
        var value = Core.validate._getValue(elementOrValue);
        if (value !== '') {

            var isDate = function(str) {
                if (!str) {
                    return false;
                }
                str = str.replace(/[/|\-|.]/g, '');
                var data = new Date();
                
                switch (str.length) {
                    case 8:
                        data = data.parseDate(str, 'ddmmyyyy');
                        break;
                    case 6:
                        data = data.parseDate(str, 'mmyyyy');
                        break;
                    case 4:
                        data = data.parseDate(str, 'ddmm');
                        break;
                    case 2:
                        data = data.parseDate(str, 'dd');
                        break;
                    default:
                        data = false;
                        break;
                }
                if (!data) {
                    return false;
                }
                return true;
            };
            if (!isDate(elementOrValue.val())) {
                if (Core.validate._isObjQuery(elementOrValue)) {
                    Core.form.setValidateTootip(elementOrValue, 'Data inválida');
                }
                return false;
            }
        }
        return true;
    },
    /**
     * Verificação de CPF
     * 
     * @param {mixed} elementOrValue
     * @returns {Boolean}
     */
    "cpf": function(elementOrValue) {
        var value = Core.validate._getValue(elementOrValue);
        if (value !== '') {

            var isCPF = function(str) {
                if (!str) {
                    return false;
                }
                str = str.replace(/[\-|.]/g, '');
                if (str.length !== 11) {
                    return false;
                }
                var c = str.substr(0, 9);
                if (c === "123456789" + '' || c === "000000000" + '' || c === "111111111" + ''
                        || c === "222222222" + '' || c === "333333333" + '' || c === "444444444" + ''
                        || c === "555555555" + '' || c === "666666666" + '' || c === "777777777" + ''
                        || c === "888888888" + '' || c === "999999999" + '' || c === "987654321") {
                    return false;
                }
                var dv = str.substr(9, 2);
                var d1 = 0;
                for (i = 0; i < 9; i++) {
                    d1 += c.charAt(i) * (10 - i);
                }
                if (d1 == 0) {
                    return false;
                }
                d1 = 11 - (d1 % 11);
                if (d1 > 9)
                    d1 = 0;
                if (dv.charAt(0) != d1) {
                    return false;
                }
                d1 *= 2;
                for (i = 0; i < 9; i++) {
                    d1 += c.charAt(i) * (11 - i);
                }
                d1 = 11 - (d1 % 11);
                if (d1 > 9) {
                    d1 = 0;
                }
                if (dv.charAt(1) != d1) {
                    return false;
                }
                return true;
            };
            if (!isCPF(value)) {
                if (Core.validate._isObjQuery(elementOrValue)) {
                    Core.form.setValidateTootip(elementOrValue, 'CPF inválido');
                }
                return false;
            }
        }
        return true;
    },
    /**
     * Verificação de CNPJ
     * 
     * @param {mixed} elementOrValue
     * @returns {Boolean}
     */
    "cnpj": function(elementOrValue) {
        var value = Core.validate._getValue(elementOrValue);
        if (value !== '') {

            var isCNPJ = function(str) {
                if (!str) {
                    return false;
                }

                str = str.replace(/[/|\-|.]/g, '');
                if (str.length !== 14) {
                    return false;
                }

                var df, resto, dac = "";
                df = 5 * str.charAt(0) + 4 * str.charAt(1) + 3 * str.charAt(2) + 2 * str.charAt(3) + 9 * str.charAt(4) + 8 * str.charAt(5) + 7 * str.charAt(6) + 6 * str.charAt(7) + 5 * str.charAt(8) + 4 * str.charAt(9) + 3 * str.charAt(10) + 2 * str.charAt(11);
                resto = df % 11;
                dac += ((resto <= 1) ? 0 : (11 - resto));
                df = 6 * str.charAt(0) + 5 * str.charAt(1) + 4 * str.charAt(2) + 3 * str.charAt(3) + 2 * str.charAt(4) + 9 * str.charAt(5) + 8 * str.charAt(6) + 7 * str.charAt(7) + 6 * str.charAt(8) + 5 * str.charAt(9) + 4 * str.charAt(10) + 3 * str.charAt(11) + 2 * parseInt(dac);
                resto = df % 11;
                dac += ((resto <= 1) ? 0 : (11 - resto));
                if (dac != (str.substring(str.length - 2, str.length))) {
                    return false;
                }
                return true;
            };
            if (!isCNPJ(elementOrValue.val())) {
                if (Core.validate._isObjQuery(elementOrValue)) {
                    Core.form.setValidateTootip(elementOrValue, 'CNPJ inválido');
                }
                return false;
            }
        }
        return true;
    },
    /**
     * Verificação de horas
     * 
     * @param {mixed} elementOrValue
     * @returns {Boolean}
     */
    "hour": function(elementOrValue) {
        var value = Core.validate._getValue(elementOrValue);
        if (value !== '') {

            var isHour = function(str) {
                if (parseInt(str.substr(0, 2), 10) > 24) {
                    return false;
                } else if (parseInt(str.substr(3, 2), 10) > 59) {
                    return false;
                } else if (parseInt(str.substr(0, 2), 10) === 24 && parseInt(str.substr(3, 2), 10) > 00) {
                    return false;
                } else {
                    return true;
                }
            };
            if (!isHour(elementOrValue.val())) {
                if (Core.validate._isObjQuery(elementOrValue)) {
                    Core.form.setValidateTootip(elementOrValue, 'Hora inválida');
                }
                return false;
            }
        }
        return true;
    },
    /**
     * Funcção que compara duas datas 
     *  mascara dos valores 'dd/mm/yyyy'  
     *   retorna > 0 para dtFinal < dtInicial  
     *   retorna = 0 para dtFinal = dtInicial  
     *   retorna < 0 para dtFinal < dtInicial  
     * 
     * @param mixed dtInicial
     * @param mixed dtFinal
     * @return boolean
     */
    "compareDate": function(dtInicial, dtFinal) {
        var dataInicial = (typeof (dtInicial) == 'object') ? dtInicial.val() : dtInicial;
        var dataFinal = (typeof (dtFinal) == 'object') ? dtFinal.val() : dtFinal;

        //Data inicio em javascript
        var arrInitDate = explode("/", dataInicial);
        var dtInicio = new Date(arrInitDate[2], arrInitDate[1] - 1, arrInitDate[0]);

        //Data fim em javascript
        var arrFinalDate = explode("/", dataFinal);
        var dtFim = new Date(arrFinalDate[2], arrFinalDate[1] - 1, arrFinalDate[0]);

        if (dtFim >= dtInicio) {
            return true;
        } else {
            return false;
        }
    },
    /**
     * Verifica se a data é válida
     *
     * @param objJquery
     * @return boolean
     */
    "isInitDateValid": function(objJquery) {
        var objFinalDate = $('#' + objJquery.attr('dependent'));
        var objInitDate = objJquery;

        var comparer = Core.validate.compareDate(objInitDate, objFinalDate);

        if (comparer) {
            objJquery.removeClass('inputError');
            objJquery.removeAttr('title');
            return true;

        } else {
            objJquery.attr('title', 'Data início maior que data fim. Favor corrigir.');
            objJquery.addClass('inputError');
            objJquery.tooltip();
            return false;
        }
    },
    /**
     * Verifica se a data é válida
     *
     * @param objJquery
     * @return boolean
     */
    "isFinalDateValid": function(objJquery) {

        var objFinalDate = objJquery;
        var objInitDate = $('#' + objJquery.attr('dependent'));
        var comparer = Core.validate.compareDate(objInitDate, objFinalDate);
        if (comparer) {
            objJquery.removeClass('inputError');
            objJquery.removeAttr('title');
            return true;
        } else {
            objJquery.attr('title', 'Data fim menor que data início. Favor corrigir.');
            objJquery.addClass('inputError');
            objJquery.tooltip();
            return false;
        }
    }

};