angular.module('app.controllers')
        .controller('UnidadeListController',
                ['$scope', 'modalService', '$http', 'grideService', function ($scope, modalService, $http, grideService) {

                        //Definições Globais da Controller
                        $scope.titleModal = 'Unidade';
                        $scope.headerModalDelete = 'Excluir Unidade';
                        $scope.titleModalDeleteConfirmation = 'Confirma a exclusão da unidade?';

                        var columnDefs = [
                            {field: 'tx_sigla', displayName: 'Sigla', minWidth: 100, width: '100'},
                            {field: 'tx_nome', displayName: 'Nome', minWidth: 150, width: '*'}
                        ];

                        $scope.gridOptions = grideService.montaGrideOptions($scope, '/academico/unidade/', columnDefs, '/academico/unidade/lista/json', 15);

                    }]);
