angular.module('app.controllers')
    .controller('DocumentoBibliotecaListController',
        ['$scope', 'modalService', '$http', '$window', 'grideService', 'appConfig',
            function ($scope, modalService, $http, $window, grideService, appConfig) {

                // alert("Funcionando");

                $(".btn-box-tool-cnec").trigger("click");

                $http({
                    method: 'GET',
                    url: appConfig.baseUrl + '/administrativo/biblioteca/listaTipoDocumento'
                }).then(function successCallback(response) {
                    $scope.tipos = response.data;
                    // console.log($scope.tipos);
                }, function errorCallback(response) {
                    return response;
                });

                //Definições Globais da Controller
                $scope.titleModal = 'Documento';
                $scope.headerModalDelete = 'Excluir Documento';
                $scope.titleModalDeleteConfirmation = 'Confirma a Exclusão do Documento?';

                var columnDefs = [
                    {field: 'num_documento', displayName: 'Número', minWidth: 30, width: 100},
                    {field: 'tipo_documento', displayName: 'Tipo Documento', minWidth: 30, width: '*'},
                    {field: 'titulo', displayName: 'Título', minWidth: 30, width: '*'},
                    {field: 'historico', displayName: 'Historico', minWidth: 150, width: '*'},
                    {field: 'dt_publicacao', displayName: 'Data Publicação', minWidth: 150, width: '*'}
                    // {
                    //     name: 'Menu',
                    //     field: 'Menu',
                    //     displayName: 'Menu',
                    //     enableFiltering: false,
                    //     enableSorting: false,
                    //     width: 200,
                    //     cellTemplate: buttonAction
                    // }
                ];

                $scope.gridOptions = grideService.montaGrideOptions($scope, '/administrativo/biblioteca/documento/', columnDefs, '/administrativo/biblioteca/documento/lista/json', 15);

            }]);
