angular.module('app.controllers')
    .controller('BaseAlunosListController',
        ['$scope', 'appConfig', '$modal', '$http', 'grideService', 'BaseAlunos', function ($scope, appConfig, $modal, $http, grideService, BaseAlunos) {

            $(".btn-box-tool-cnec").trigger("click");

            $scope.titleModal = 'Candidato';

            $scope.modaldetalhes = function ($id) {

                $scope.idcandidato = $id;

                $modal({
                    title: $scope.titleModal,
                    show: true,
                    html: true,
                    id: 'modalView',
                    scope: $scope,
                    size: 'lg',
                    templateUrl: '/../partials/modal-siscon.html',
                    controller: 'CandidatoModal'
                });

            };

            $scope.changeModalidade = function () {
                BaseAlunos.getComboEdital($scope);
            };

            $scope.changeEdital = function () {
                BaseAlunos.getComboUf($scope);
            };

            $scope.changeEstado = function () {
                BaseAlunos.getComboUnidade($scope);
            };

            $scope.changeUnidade = function () {
                BaseAlunos.getComboCurso($scope);
            };

            $scope.mostraGride = function () {
                $("#divGride").css({'display': "block"});
            };

            $scope.escondeGride = function () {
                $("#divGride").css({'display': "none"});
            };

            var buttonAction = '<button tooltip-append-to-body="true" uib-tooltip="Visualizar" tooltip-placement="bottom-left" class="btn btn-xs btn-info" ng-click="grid.appScope.modaldetalhes(row.entity.id); $event.stopPropagation();"><span class="glyphicon glyphicon-eye-open"></span></button> ';

            var columnDefs = [
                {field: 'alumatricula', displayName: 'Matrícula', minWidth: 100, width: 150},
                {field: 'alunomecompleto', displayName: 'Nome'},
                {field: 'serie', displayName: 'Série', minWidth: 100, width: 100},
                {
                    name: 'Menu',
                    field: 'Menu',
                    displayName: 'Menu',
                    enableFiltering: false,
                    enableSorting: false,
                    width: 140,
                    cellTemplate: buttonAction
                }
            ];

            // var $filterOptionsDefault = {};
            // $filterOptionsDefault.modalidadeFilter = 'SUP';
            // $filterOptionsDefault.editalFilter = '2015-2';
            // $filterOptionsDefault.ufFilter = 'MG';
            // $filterOptionsDefault.unidadeFilter = '1916';
            // $filterOptionsDefault.cursoFilter = 'CON';

            $scope.gridOptions = grideService.montaGrideOptions($scope, '/siscon/basealunos/', columnDefs, '/siscon/basealunos/lista/json', 15, false, null, 140, null, false);

        }]);
