Cnec.alert = new (function () {

    var _alertBoxCounter;

    var _optionsValidation = function (options)
    {
        var optionsValid = {};
        try {
            if (typeof (options) === 'string') {
                var message = options;
                options = {"message": message};
            }

            if (typeof (options) !== 'object') {
                throw new Core.Exception('Cnec.messageBox.question(): Sem opções válidas.');
            }

            optionsValid.message = options.message || '';
            optionsValid.callback = options.callback || null;

            return optionsValid;
        } catch (e) {
            Core.exceptionHandler(e);
        }
        return optionsValid;
    };

    var _show = function (options)
    {
        var id = sprintf('alertBox-%d', ++_alertBoxCounter);

        var html = '<div class="alert %s alert-dismissable fade in" id="' + id + '">';
        html += '<button type="button" class="close" data-dismiss="alert" aria-hidden="true">×</button>';

        var cssMessage = null;
        var typeMessage = null;
        if (options.type == 'success') {
            cssMessage = 'alert-success';
            typeMessage = '<i class="icon fa fa-check"></i>';
        } else if (options.type == 'critical') {
            cssMessage = 'alert-danger';
            typeMessage = '<i class="icon fa fa-ban"></i>';
        } else if (options.type == 'warning') {
            cssMessage = 'alert-warning';
            typeMessage = '<i class="icon fa fa-warning"></i>';
        } else if (options.type == 'information') {
            cssMessage = 'alert-info';
            typeMessage = '<i class="icon fa fa-info"></i>';
        }
        html += typeMessage + ' ' + options.message;

        html += '</div>';

        html = sprintf(html, cssMessage);
        var closeAction = function () {
            Core.callbackHandler(options.callback);
        };

        $('#alert-box').append(html);
        var alertBox = $('#' + id);
        alertBox.find('.close').click(closeAction);
    };

    this.success = function (options)
    {
        options = _optionsValidation(options);
        options.type = 'success';
        _show(options);
    };

    this.information = function (options)
    {
        options = _optionsValidation(options);
        options.type = 'information';
        _show(options);
    };

    this.warning = function (options)
    {
        options = _optionsValidation(options);
        options.type = 'warning';
        _show(options);
    };

    this.critical = function (options)
    {
        options = _optionsValidation(options);
        options.type = 'critical';
        _show(options);
    };

    this.question = function (options)
    {
        options = _optionsValidation(options);
        options.type = 'question';
        _show(options);
    };
});