angular.module('app.controllers')
        .controller('CursoListController',
                ['$scope', 'modalService', '$http', '$window', 'grideService', 'appConfig', function ($scope, modalService, $http, $window, grideService, appConfig) {

                        //Definições Globais da Controller
                        $scope.titleModal = 'Curso';
                        $scope.headerModalDelete = 'Excluir Curso';
                        $scope.titleModalDeleteConfirmation = 'Confirma a exclusão do curso?';

                        //Botões customizados
                        var buttonAction = '<button uib-tooltip="Habilitações" tooltip-placement="bottom-right" ng-show="row.entity.CanListHabilitacao == true" class="btn btn-xs btn-primary" ng-click="grid.appScope.habilitacaoAction(row.entity.id); $event.stopPropagation();"><span class="glyphicon glyphicon-book"></span></button>  ';
                        buttonAction += '<button uib-tooltip="Unidades" tooltip-placement="bottom-right" ng-show="row.entity.CanListUnidadeCurso == true" class="btn btn-xs btn-primary" ng-click="grid.appScope.unidadeCursoAction(row.entity.id); $event.stopPropagation();"><i class="fa fa-university"></i></button>  ';

                        //Ações dos botões customizados
                        $scope.habilitacaoAction = function ($id) {
                            $window.location.href = appConfig.baseUrl + '/academico/habilitacao/curso/' + $id;
                        };

                        $scope.unidadeCursoAction = function ($id) {
                            $window.location.href = appConfig.baseUrl + '/academico/unidade-curso/curso/' + $id;
                        };

                        var columnDefs = [
                            {field: 'tx_sigla', displayName: 'Sigla', minWidth: 50, width: 50},
                            {field: 'tx_nome', displayName: 'Nome', minWidth: 150, width: '*'},
                            {
                                name: 'Menu',
                                field: 'Menu',
                                displayName: 'Menu',
                                enableFiltering: false,
                                enableSorting: false,
                                width: 140,
                                cellTemplate: buttonAction
                            }
                        ];

                        $scope.gridOptions = grideService.montaGrideOptions($scope, '/academico/curso/', columnDefs, '/academico/curso/lista/json', 15);

                    }]);
