angular.module('app.controllers')
    .controller('CestaListController',
        ['$scope', 'modalService', '$http', '$window', 'grideService', 'appConfig',
            function ($scope, modalService, $http, $window, grideService, appConfig) {

                // alert("Funcionando");

                $(".btn-box-tool-cnec").trigger("click");

                //Definições Globais da Controller
                $scope.titleModal = 'Cesta';
                $scope.headerModalDelete = 'Excluir Cesta';
                $scope.titleModalDeleteConfirmation = 'Confirma a Exclusão da Cesta?';

                var columnDefs = [
                    {field: 'id', displayName: '#', minWidth: 50, width: 50},
                    {field: 'nome', displayName: 'Nome', minWidth: 30, width: '*'},
                    {field: 'descricao', displayName: 'Descrição', minWidth: 150, width: '*'},
                    {field: 'PatrimonioMask', displayName: 'Patrimônio', minWidth: 150, width: '*'},
                    {field: 'ValorMask', displayName: 'Valor', minWidth: 150, width: '*'}
                    // {
                    //     name: 'Menu',
                    //     field: 'Menu',
                    //     displayName: 'Menu',
                    //     enableFiltering: false,
                    //     enableSorting: false,
                    //     width: 200,
                    //     cellTemplate: buttonAction
                    // }
                ];

                $scope.gridOptions = grideService.montaGrideOptions($scope, '/administrativo/cesta/', columnDefs, '/administrativo/cesta/lista/json', 15);

            }]);
