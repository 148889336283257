angular.module('app.controllers')
    .controller('FaturaListController',
        ['$scope', 'modalService', '$http', '$window', 'grideService', 'appConfig',
            function ($scope, modalService, $http, $window, grideService, appConfig) {

                // alert("Funcionando");

                $(".btn-box-tool-cnec").trigger("click");

                //Definições Globais da Controller
                $scope.titleModal = 'Fatura';
                $scope.headerModalDelete = 'Excluir Fatura';
                $scope.titleModalDeleteConfirmation = 'Confirma a Exclusão do Fatura?';

                var columnDefs = [
                    // {field: 'id', displayName: '#', minWidth: 50, width: 50},
                    {field: 'valor', displayName: 'Valor', minWidth: 30, width: '*'},
                    {field: 'associado_nome', displayName: 'Associado', minWidth: 150, width: '*'},
                    {field: 'mes_ref', displayName: 'Mês', minWidth: 150, width: '*'},
                    {field: 'ano_ref', displayName: 'Ano', minWidth: 150, width: '*'},
                    {field: 'dt_vencimento', displayName: 'Data Vencimento', minWidth: 150, width: '*'},
                    {field: 'dt_pagamento', displayName: 'Data Pagamento', minWidth: 150, width: '*'},
                    // {
                    //     name: 'Menu',
                    //     field: 'Menu',
                    //     displayName: 'Menu',
                    //     enableFiltering: false,
                    //     enableSorting: false,
                    //     width: 200,
                    //     cellTemplate: buttonAction
                    // }
                ];

                $scope.gridOptions = grideService.montaGrideOptions($scope, '/administrativo/fatura/', columnDefs, '/administrativo/fatura/lista/json', 15);

            }]);
