Cnec.listSelector = new (function () {
    var selectWithoutControl = function () {
        $('select.list-selector option').mousedown(function (e) {
            e.preventDefault();
            $(this).prop('selected', !$(this).prop('selected'));
            return false;
        });
    };

    this.start = function () {
        selectWithoutControl();
    };

    /**
     * Função que transfere os dados
     */
    var getElements = function (instance, isList, isAll) {
        let box = instance.parent();
        let boxName = box.attr('box-name');
        let root = (isList) ? 'list-' + boxName : 'target-' + boxName;
        let target = (!isList) ? 'list-' + boxName : 'target-' + boxName;
        let selected = (isAll) ? '' : ':selected';
        let elements = $('select[name="' + root + '[]"] option' + selected);
        elements.hide().fadeOut(1000);
        $('select[name="' + target + '[]"]').append(elements);
        elements.hide().fadeIn(1000);
        createInputs(boxName, isList, elements);
        createController(boxName);
    };

    /**
     * Cria os inputs do banco
     */
    var createInputs = function (boxName, isList, elements) {
        if (isList) {
            elements.each(function (i, index) {
                $('<input>').attr({
                    type: 'hidden',
                    name: boxName + '[]',
                    value: $(index).val()
                }).appendTo('.box-' + boxName);
            });
        } else {
            elements.each(function (i, index) {
                let val = $(index).val();
                $('.box-' + boxName + ' input[value=' + val + ']').remove();
            });
        }
    };

    var createController = function (boxName) {
        let inputs = $('.box-' + boxName + ' input').not('.controller-' + boxName).length;
        
        if (inputs == 0) {
            $('.controller-' + boxName).val(0);
        } else {
            $('.controller-' + boxName).val(inputs);
        }
    };

    /**
     * Apenas os selecionados
     */
    $('.get-one').click(function () {
        getElements($(this), true, false);

    });

    /**
     * Adiciona todos
     */
    $('.get-all').click(function () {
        getElements($(this), true, true);
    });

    /**
     * Remova apenas selecionados
     */
    $('.remove-one').click(function () {
        getElements($(this), false, false);

    });

    /**
     * Remove todos
     */
    $('.remove-all').click(function () {
        getElements($(this), false, true);
    });

    return this;
});

