angular.module('app.controllers')
        .controller('UnidadeCurriculoListController',
                ['$scope', 'modalService', '$http', '$window', 'grideService', 'appConfig', function ($scope, modalService, $http, $window, grideService, appConfig) {

                        //Definições Globais da Controller
                        $scope.titleModal = 'Unidade';
                        $scope.headerModalDelete = 'Remover Unidade';
                        $scope.titleModalDeleteConfirmation = 'Confirma a remoção da unidade?';

                        var filterOptionsDefault = {};
                        filterOptionsDefault.curriculo = $window.acad.curriculo_id;

                        var columnDefs = [
                            {field: 'unidade_tx_nome', displayName: 'Unidade', minWidth: 150, width: '*'}
                        ];

                        $scope.gridOptions = grideService.montaGrideOptions($scope, '/academico/unidade-curriculo/', columnDefs, '/academico/unidade-curriculo/lista/json', 15, true, null, 140, filterOptionsDefault);

                    }]);
