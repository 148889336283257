angular.module('app.controllers').controller('PessoaListController', ['$scope', 'modalService', '$http', '$window', 'grideService', 'Pessoa', 'appConfig',
    function($scope, modalService, $http, $window, grideService, Pessoa, appConfig) {

        /**
         * [filterOptions autocomplete email]
         * @type {Object}
         */
        // $scope.filterOptions = {};
        // $scope.filterOptions.email = "";
        // $scope.emails = Pessoa.getListaPessoa();

        /**
         * [inicio chamada da gride]
         * @type {gridOptions}
         */
        var columnDefs = [{
            field: 'tx_nome',
            displayName: 'Nome',
            minWidth: '150',
            width: '*',
            cellTemplate: '<div ng-show="row.entity.CanEdit == true" style="text-decoration:underline;color:blue;text-align:left;cursor:pointer" ng-click="grid.appScope.edit(row.entity.id)">{{COL_FIELD}}</div>'
        }, {
            field: 'nr_cpf',
            minWidth: '150',
            width: '*',
            displayName: 'CPF',
        }, {
            field: 'dt_nascimento',
            minWidth: '150',
            width: '*',
            displayName: 'Data de nascimento'
        }];

        //chamada grid factory
        $scope.gridOptions = grideService.montaGrideOptions($scope, '/academico/pessoa/', columnDefs, '/academico/pessoa/lista/json/', 5);

        /**
         * toastr options
         */
        toastr.options = {
            "closeButton": true,
            "debug": false,
            "newestOnTop": false,
            "progressBar": false,
            "positionClass": "toast-top-center",
            "preventDuplicates": true,
            "onclick": null,
            "showDuration": "1000",
            "hideDuration": "1000",
            "timeOut": "5000",
            "extendedTimeOut": "1000",
            "showEasing": "swing",
            "hideEasing": "linear",
            "showMethod": "fadeIn",
            "hideMethod": "fadeOut"
        }
        //delete scope
        $scope.remove = function(url) {
            modalService.showDeleteConfirmation('Excluir Usuário', 'Confirma a exclusão do usuário?').then(function() {
                $http({
                    method: 'DELETE',
                    url: url
                }).then(function successCallback(response) {
                    if (response.data.error == false) {
                        toastr["success"](response.data.msg);
                        setTimeout('window.location.reload(true)', 1000);
                    } else {
                        toastr["error"](response.data.msg);
                    }
                }, function errorCallback(response) {
                    toastr["error"]('Ocorreu um erro ao realizar a operação');
                });
            });
        };
    }
]);