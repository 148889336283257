angular.module('app.services')
        .service('BaseAlunos', ['$http', function ($http) {

                this.getComboEdital = function ($scope) {

                    $scope.filterOptions.editalFilter = null;
                    $scope.filterOptions.ufFilter = null;
                    $scope.filterOptions.unidadeFilter = null;
                    $scope.filterOptions.cursoFilter = null;
                    $scope.editais = null;
                    $scope.estados = null;
                    $scope.unidades = null;
                    $scope.cursos = null;

                    if ($scope.filterOptions.modalidadeFilter) {
                        var paramFiltro = [];
                        var param = {"modalidadeFilter": $scope.filterOptions.modalidadeFilter};
                        paramFiltro['filtro'] = JSON.stringify(param);
                        return $http({
                            method: 'GET',
                            url: '/siscon/basealunos/get-combo-edital/json',
                            params: paramFiltro
                        }).then(function successCallback(response) {
                            $scope.editais = response.data;
                        }, function errorCallback(response) {
                            $scope.editais = {'': "Nenhum edital encontrado!"};
                        });
                    }

                    $scope.$apply();
                };

                this.getComboUf = function ($scope) {

                    $scope.filterOptions.ufFilter = null;
                    $scope.filterOptions.unidadeFilter = null;
                    $scope.filterOptions.cursoFilter = null;
                    $scope.estados = null;
                    $scope.unidades = null;
                    $scope.cursos = null;

                    if ($scope.filterOptions.editalFilter) {
                        var paramFiltro = [];
                        var param = {
                            "modalidadeFilter": $scope.filterOptions.modalidadeFilter,
                            "editalFilter": $scope.filterOptions.editalFilter
                        };
                        paramFiltro['filtro'] = JSON.stringify(param);
                        return $http({
                            method: 'GET',
                            url: '/siscon/basealunos/get-combo-uf/json',
                            params: paramFiltro
                        }).then(function successCallback(response) {
                            $scope.estados = response.data;
                        }, function errorCallback(response) {
                            $scope.estados = {'': "Nenhum estado encontrado!"};
                        });
                    }

                    $scope.$apply();
                };

                this.getComboUnidade = function ($scope) {

                    $scope.filterOptions.unidadeFilter = null;
                    $scope.filterOptions.cursoFilter = null;
                    $scope.unidades = null;
                    $scope.cursos = null;

                    if ($scope.filterOptions.ufFilter) {
                        var paramFiltro = [];
                        var param = {
                            "modalidadeFilter": $scope.filterOptions.modalidadeFilter,
                            "editalFilter": $scope.filterOptions.editalFilter,
                            "ufFilter": $scope.filterOptions.ufFilter
                        };
                        paramFiltro['filtro'] = JSON.stringify(param);
                        return $http({
                            method: 'GET',
                            url: '/siscon/basealunos/get-combo-unidade/json',
                            params: paramFiltro
                        }).then(function successCallback(response) {
                            $scope.unidades = response.data;
                        }, function errorCallback(response) {
                            $scope.unidades = {'': "Nenhuma unidade encontrada!"};
                        });
                    }

                    $scope.$apply();
                };

                this.getComboCurso = function ($scope) {

                    $scope.filterOptions.cursoFilter = null;
                    $scope.cursos = null;

                    if ($scope.filterOptions.unidadeFilter) {
                        var paramFiltro = [];
                        var param = {
                            "modalidadeFilter": $scope.filterOptions.modalidadeFilter,
                            "editalFilter": $scope.filterOptions.editalFilter,
                            "ufFilter": $scope.filterOptions.ufFilter,
                            "unidadeFilter": $scope.filterOptions.unidadeFilter
                        };
                        paramFiltro['filtro'] = JSON.stringify(param);
                        return $http({
                            method: 'GET',
                            url: '/siscon/basealunos/get-combo-curso/json',
                            params: paramFiltro
                        }).then(function successCallback(response) {

                            $scope.cursos = response.data;
                        }, function errorCallback(response) {
                            $scope.cursos = {'': "Nenhum curso encontrado!"};
                        });
                    }

                    $scope.$apply();
                };

            }]);