angular.module('app.controllers').controller('PerfilListController', ['$scope', '$http', 'grideService', function($scope, $http, grideService) {
    //definições globais da controller
    $scope.titleModal = 'Perfil';
    $scope.headerModalDelete = 'Excluir Perfil';
    $scope.titleModalDeleteConfirmation = 'Confirma a exclusão do perfil?';
    //gride config
    var columnDefs = [{
        field: 'tx_perfil',
        displayName: 'Nome',
        minWidth: '150',
        width: '*'
    }, {
        field: 'tx_nome_exibicao',
        displayName: 'Nome exibição',
        minWidth: '150',
        width: '*'
    }, {
        field: 'tx_descricao',
        displayName: 'Descrição',
        minWidth: '150',
        width: '*'
    }];
    $scope.gridOptions = grideService.montaGrideOptions($scope, '/seguranca/perfis/', columnDefs, '/seguranca/listaPerfil/', 15);
}]);