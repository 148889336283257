/**
 * Created by Mateus on 04/12/2018.
 */
angular.module('app.controllers')
    .controller('PatrimonioMoedaListController',
        ['$scope', 'modalService', '$http', '$window', 'grideService','appConfig',
            function ($scope, modalService, $http, $window, grideService, appConfig ) {

                $(".btn-box-tool-cnec").trigger("click");

                //Definições Globais da Controller
                $scope.titleModal = 'Moedas';
                $scope.headerModalDelete = 'Excluir Moeda';
                $scope.titleModalDeleteConfirmation = 'Confirma a Exclusão da Moeda?';

                // $scope.produtoCategorias = function ($id) {
                //     $window.location.href = appConfig.baseUrl + '/administrativo/produto-categoria' + '/' + $id;
                // };
                //
                // $scope.produtos = function ($id) {
                //     $window.location.href = appConfig.baseUrl + '/administrativo/produto' + '/' + $id;
                // };
                //
                // $scope.pedidos = function ($id) {
                //     $window.location.href = appConfig.baseUrl + '/administrativo/pedido' + '/' + $id;
                // };
                //
                // $scope.cardapio = function ($id) {
                //     $window.location.href = appConfig.baseUrl + '/administrativo/estabelecimento-produto' + '/' + $id;
                // };
                $scope.cotacao = function ($id) {
                    $window.location.href = appConfig.baseUrl + '/administrativo/cotacao' + '/' + $id;
                };

                $scope.edit = function ($id) {
                    $window.location.href = appConfig.baseUrl + '/administrativo/patrimonio-moeda/deletar' + '/' + $id;
                };
                $scope.delete = function ($id) {
                    $window.location.href = appConfig.baseUrl + '/administrativo/patrimonio-moeda/remover' + '/' + $id;
                };

                //
                // $scope.mesas = function ($id) {
                //     $window.location.href = appConfig.baseUrl + '/administrativo/mesa' + '/' + $id;
                // };
                //
                // $scope.usuarios = function ($id) {
                //     $window.location.href = appConfig.baseUrl + '/administrativo/nucleo-usuario' + '/' + $id;
                // };

                // var  buttonCotacao = '<button tooltip-append-to-body="true" uib-tooltip="Cotação" tooltip-placement="bottom-left" class="btn btn-xs btn-info" ng-click="grid.appScope.cotacao(row.entity.id); $event.stopPropagation();"><span class="fa fa-line-chart"></span></button>';
                var buttonCotacao = '<button tooltip-append-to-body="true" uib-tooltip="Editar" tooltip-placement="bottom-left" class="btn btn-xs btn-warning" ng-click="grid.appScope.edit(row.entity.id); $event.stopPropagation();"><span class="glyphicon glyphicon-edit"></span></button>';
                buttonCotacao += '<button tooltip-append-to-body="true" uib-tooltip="Excluir" tooltip-placement="bottom-left" class="btn btn-xs btn-danger" ng-click="grid.appScope.delete(row.entity.id); $event.stopPropagation();"><span class="glyphicon glyphicon-trash"></span></button>';
                // buttonAction += '<button tooltip-append-to-body="true" uib-tooltip="Produtos" tooltip-placement="bottom-left" class="btn btn-xs btn-info" ng-click="grid.appScope.produtos(row.entity.id); $event.stopPropagation();"><i class="fa fa-file-image-o .text-azul-c"></i></button>';
                // buttonAction += '<button tooltip-append-to-body="true" uib-tooltip="Cardápio" tooltip-placement="bottom-left" class="btn btn-xs btn-info" ng-click="grid.appScope.cardapio(row.entity.id); $event.stopPropagation();"><span class="glyphicon glyphicon-list-alt"></span></button>';
                // buttonAction += '<button tooltip-append-to-body="true" uib-tooltip="Mesas" tooltip-placement="bottom-left" class="btn btn-xs btn-info" ng-click="grid.appScope.mesas(row.entity.id); $event.stopPropagation();"><span class="glyphicon glyphicon-unchecked"></span></button>';
                // buttonAction += '<button tooltip-append-to-body="true" uib-tooltip="Usuários" tooltip-placement="bottom-left" class="btn btn-xs btn-info" ng-click="grid.appScope.usuarios(row.entity.id); $event.stopPropagation();"><span class="glyphicon glyphicon-user"></span></button>';
                // var buttonAction = '<button tooltip-append-to-body="true" uib-tooltip="Pedidos" tooltip-placement="bottom-left" class="btn btn-xs btn-info" ng-click="grid.appScope.pedidos(row.entity.id); $event.stopPropagation();"><span class="glyphicon glyphicon-home"></span></button>';
                // buttonAction += '<button tooltip-append-to-body="true" uib-tooltip="Cotação" tooltip-placement="bottom-left" class="btn btn-xs btn-info" ng-click="grid.appScope.moeda(row.entity.id); $event.stopPropagation();"><span class="fa fa-line-chart"></span></button>';
                // var buttonAction = '<button tooltip-append-to-body="true" uib-tooltip="PBIP" tooltip-placement="bottom-left" class="btn btn-xs btn-info" ng-click="grid.appScope.pbip(row.entity.id); $event.stopPropagation();">PBIP</button>';
                // buttonAction += '<button tooltip-append-to-body="true" uib-tooltip="Conta Corrente" tooltip-placement="bottom-left" class="btn btn-xs btn-info" ng-click="grid.appScope.tldc(row.entity.id); $event.stopPropagation();">Conta Corrente</button>';
                // buttonAction += '<button tooltip-append-to-body="true" uib-tooltip="TLDC-Aquisição" tooltip-placement="bottom-left" class="btn btn-xs btn-info" ng-click="grid.appScope.moeda(row.entity.id); $event.stopPropagation();">TLDC-Aquisição</button>';

                var columnDefs = [
                    {field: 'sigla', displayName: 'Sigla', minWidth: 70, width: 70},
                    {field: 'nome', displayName: 'Nome', minWidth: 100, width: '*'},
                    {field: 'descricao', displayName: 'Histórico', minWidth: 100, width: '*'},
                    {
                        name: 'Menu',
                        field: 'Menu',
                        displayName: 'Menu',
                        enableFiltering: false,
                        enableSorting: false,
                        width: 150,
                        cellTemplate: buttonCotacao
                    }
                ];
                // console.log(columnDefs);
                $scope.gridOptions = grideService.montaGrideOptions($scope, '/administrativo/patrimonio-moeda/', columnDefs, '/administrativo/patrimonio-moeda/lista/json', 15);

            }]);
