angular.module('app.controllers')
        .controller('DisciplinaListController',
                ['$scope', 'modalService', '$http', 'grideService', function ($scope, modalService, $http, grideService) {

                        //Definições Globais da Controller
                        $scope.titleModal = 'Disciplina';
                        $scope.headerModalDelete = 'Excluir Disciplina';
                        $scope.titleModalDeleteConfirmation = 'Confirma a exclusão da disciplina?';

                        var columnDefs = [
                            {field: 'tx_sigla', displayName: 'Sigla'},
                            {field: 'tx_nome', displayName: 'Nome'},
                            {field: 'nr_carga_horaria', displayName: 'C.H'},
                            {field: 'nr_creditos_academicos', displayName: 'Créditos'},
                            {field: 'disciplina_modalidade_tx_nome', displayName: 'Modalidade'}
                        ];

                        $scope.gridOptions = grideService.montaGrideOptions($scope, '/academico/disciplina/', columnDefs, '/academico/disciplina/lista/json', 15);


                    }]);
