angular.module('app.controllers')
    .controller('CandidatoModal',
        ['$scope', '$http', '$sce', 'appConfig', '$q', function ($scope, $http, $sce, appConfig, $q) {

            $scope.getDados = function (ficha) {

                $scope.class.active = ficha.gbsacronimo;
                $scope.isCollapsed = true;
                $scope.conteudo = "";

                $http({
                    method: 'GET',
                    url: appConfig.baseUrl + '/siscon/basealunos/get-dados/' + $scope.idcandidato + '-' + ficha.gbsacronimo + '-' + ficha.fcsfichacabecalhosecaocodigo
                }).then(function successCallback(response) {
                    $scope.conteudo = $sce.getTrustedHtml(response.data.html);
                }, function errorCallback(response) {
                    toastr["error"]('Ocorreu um erro ao realizar a operação');
                });

                $scope.isCollapsed = false;
            };

            $scope.iniciaModal = function () {
                $scope.class = {};
                $scope.isCollapsed = false;

                $http({
                    method: 'GET',
                    url: appConfig.baseUrl + '/siscon/basealunos/get-fichas/json/' + $scope.idcandidato
                }).then(function successCallback(response) {
                    $scope.fichas = response.data;

                    $scope.fichas.push(JSON.parse('{"fcsfichacabecalhosecaocodigo": 0,"ficfichacodigo":"' + $scope.fichas[0].ficfichacodigo + '","gbsacronimo":"ANXANEXO","gbsnome":"Anexos"}'));

                    $scope.getDados($scope.fichas[0]);

                }, function errorCallback(response) {
                    toastr["error"]('Ocorreu um erro ao realizar a operação');
                });
            };

            $scope.iniciaModal();

        }]);
