angular.module('app.controllers')
    .controller('AssociadoListController',
        ['$scope', 'modalService', '$http', '$window', 'grideService', 'appConfig', '$cookies', '$timeout',
            function ($scope, modalService, $http, $window, grideService, appConfig, $cookies, $timeout) {

                $(".btn-box-tool-cnec").trigger("click");

                //Definições Globais da Controller
                $scope.titleModal = 'Associado';
                $scope.headerModalDelete = 'Excluir Associado';
                $scope.titleModalDeleteConfirmation = 'Confirma a Exclusão do Associado?';


                var filterOptionsDefault = {};
                //filterOptionsDefault.nucleoFilter = $window.acad.filtro_nucleo_id;

                $scope.filterOptions = {};
                $scope.filterOptions.nucleoFilter = $window.acad.filtro_nucleo_id;
                $scope.filterOptions.nomeFilter = '';


                //ajax query
                $http({
                    method: 'GET',
                    url: appConfig.baseUrl + '/administrativo/nucleo/listaNucleoByUsuario/json',
                }).then(function successCallback(response) {
                    $scope.nucleos = response.data;
                }, function errorCallback(response) {
                    return response;
                });

                $scope.mostraGride = function () {
                    $("#divGride").css({'display': "block"});
                    var today = new Date();
                    var expiresValue = new Date(today);
                    //Set 'expires' option in 2 hours
                    expiresValue.setMinutes(today.getMinutes() + 120);
                    $cookies.put('searchFilter', JSON.stringify($scope.filterOptions), expiresValue);
                }

                $scope.escondeGride = function () {
                    $("#divGride").css({'display': "none"});
                    $scope.mostraNovo = false;
                    $scope.filterOptions = {};
                    $scope.filterOptions.nucleoFilter = 'Todos';
                    $scope.filterOptions.nomeFilter = '';
                    filterOptionsSearch = {};
                    delete filterOptionsSearch;
                    $cookies.remove("searchFilter");
                };

                var filterOptionsSearch = ($cookies.get('searchFilter') !== undefined) ? JSON.parse($cookies.get('searchFilter')) : null;

                if (angular.isObject(filterOptionsSearch)) {


                    if (isset(filterOptionsSearch.nucleoFilter) && filterOptionsSearch.nucleoFilter !== undefined) {
                        $scope.filterOptions.nucleoFilter = filterOptionsSearch.nucleoFilter;
                    }

                    if (isset(filterOptionsSearch.nomeFilter) && filterOptionsSearch.nomeFilter !== undefined) {
                        $scope.filterOptions.nomeFilter = filterOptionsSearch.nomeFilter;
                    }

                    $timeout(function () {
                        angular.element('#pesquisar').trigger('click');
                    }, 100);

                }

                $scope.pbip = function ($id) {
                    $window.location.href = appConfig.baseUrl + '/administrativo/associado-pbip' + '/' + $id;
                };

                $scope.tldc = function ($id) {
                    $window.location.href = appConfig.baseUrl + '/administrativo/tldc' + '/' + $id;
                };
                //Página para a solitação de compra de moedas
               /* $scope.moeda = function ($id) {
                    $window.location.href = appConfig.baseUrl + '/administrativo/patrimonio-moeda/solicitar' + '/' + $id;
                };
                //Página para a solicitação de compra de patrimonios
                $scope.patrimonio = function ($id) {
                    $window.location.href = appConfig.baseUrl + '/administrativo/patrimonio/solicitar' + '/' + $id;
                };*/
                //Página para a solitação de compra de moedas
                $scope.moeda = function ($id) {
                    $window.location.href = appConfig.baseUrl + '/administrativo/associado';
                };
                //Página para a solicitação de compra de patrimonios
                $scope.patrimonio = function ($id) {
                    $window.location.href = appConfig.baseUrl + '/administrativo/associado';
                };


                var buttonAction = '<button tooltip-append-to-body="true" uib-tooltip="PBIP" tooltip-placement="bottom-left" class="btn btn-xs btn-info" ng-click="grid.appScope.pbip(row.entity.id); $event.stopPropagation();">PBIP</button>';
                buttonAction += '<button tooltip-append-to-body="true" uib-tooltip="Moeda Aquisição" tooltip-placement="bottom-left" class="btn btn-xs btn-info" ng-click="grid.appScope.moeda(row.entity.id); $event.stopPropagation();">Moeda</button>';
                buttonAction += '<button tooltip-append-to-body="true" uib-tooltip="Patrimônio Aquisição" tooltip-placement="bottom-left" class="btn btn-xs btn-info" ng-click="grid.appScope.patrimonio(row.entity.id); $event.stopPropagation();">Patrimônio</button>';
                buttonAction += '<button tooltip-append-to-body="true" uib-tooltip="C/C" tooltip-placement="bottom-left" class="btn btn-xs btn-info" ng-click="grid.appScope.tldc(row.entity.id); $event.stopPropagation();">C/C</button>';


                // var inadimplente = '<span class="label label-danger" style="border-radius: 2.25em;">&nbsp;&nbsp;</span>';
                // var adimplente = '<span class="label label-success" style="border-radius: 2.25em;">&nbsp;&nbsp;</span>';
                //
                // if(){
                //      $scope.situacao = inadimplente;
                // }else{
                //     $scope.situacao = adimplente;
                // }

                var columnDefs = [
                    // {field: 'id', displayName: '#', minWidth: 50, width: 50},
                    // {field: 'cpf', displayName: 'CPF', minWidth: 30, width: '*'},
                    {field: 'nome', displayName: 'Nome', minWidth: 150, width: '*'},
                    {field: 'email', displayName: 'Email', minWidth: 150, width: '*'},
                    {field: 'celular', displayName: 'Celular', minWidth: 90, width: '*'},
                    {field: 'nucleo_sigla', displayName: 'Núcleo', minWidth: 160, width: '*'},
                    {field: 'situacao', displayName: 'Situação', minWidth: 100, width: '*'},
                    {
                        name: 'Menu',
                        field: 'Menu',
                        displayName: 'Menu',
                        enableFiltering: false,
                        enableSorting: false,
                        width: 220,
                        cellTemplate: buttonAction,
                    },
                    // {
                    //     name: 'Solicitar',
                    //     field: 'Solicitar',
                    //     displayName: 'Solicitar',
                    //     enableFiltering: false,
                    //     enableSorting: false,
                    //     width: 100,
                    //     cellTemplate: buttonActionMoeda,
                    // },
                    // {
                    //     name: 'Situação',
                    //     // field: 'situacao',
                    //     displayName: 'Situação',
                    //     enableFiltering: false,
                    //     enableSorting: false,
                    //     width: 100,
                    //     cellTemplate: $scope.situacao
                    // }
                ];

                $scope.gridOptions = grideService.montaGrideOptions($scope, '/administrativo/associado/', columnDefs, '/administrativo/associado/lista/json', 15);

            }]);
