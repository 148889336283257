angular.module('app.controllers')
        .controller('EditalListController',
                ['$scope', 'modalService', '$http', '$window', 'grideService', 'appConfig', 'Edital', '$timeout', '$cookies', '$modal', 'Beneficio',
                    function ($scope, modalService, $http, $window, grideService, appConfig, Edital, $timeout, $cookies, $modal, Beneficio) {

                        $(".btn-box-tool-cnec").trigger("click");

                        //definições globais da controller
                        $scope.titleModal = 'Edital';
                        $scope.headerModalDelete = 'Excluir Edital';
                        $scope.titleModalDeleteConfirmation = 'Confirma a exclusão do edital?';

                        /**
                         *
                         * INIT vars default
                         */
                        $scope.filterOptions = {};
                        $scope.filterOptions.tipoEducacaoFilter = '';
                        $scope.filterOptions.txSiglaEstadoFilter = '';
                        $scope.filterOptions.unidadeFilter = '';

                        //$scope.unidadeFilter = '';
                        $scope.unidades = [];

                        $scope.estados = Beneficio.getComboEstado();

                        // $scope.arrTipoEdu = {'IN' : "Infantil", 'FU' : "Fundamental", 'ME' : "Médio", 'PR' : "Profissional", 'EJ' : "EJA", 'SU': "Superior Presencial", 'EA' : "Superior - EAD"};
                        $scope.arrTipoEdu = {'EP' : "Educação Básica/Profissional", 'SU': "Superior Presencial", 'EA' : "Superior - EAD"};
                        $scope.tiposEduca = [];
                        angular.forEach($scope.arrTipoEdu, function (label, value) {
                            this.push({value: value, label: label});
                        }, $scope.tiposEduca);


                        $scope.novoEdital = function () {
                            $window.location.href = appConfig.baseUrl + '/beneficio/edital/cadastro';
                        }

                        $scope.novoProcesso = function (idEdital, tipoEdital, idUnidade, txUfUnidade) {
                            if (tipoEdital == 'I') {
                                $window.location.href = appConfig.baseUrl + '/beneficio/dados-preliminares/cadastro/' + null + '/'+ null + '/' + null + '/' + null + '/' + btoa(idEdital);
                            } else {
                                var today = new Date();
                                var expiresValue = new Date(today);
                                //Set 'expires' option in 2 hours
                                expiresValue.setMinutes(today.getMinutes() + 120);
                                $scope.searchFilterEdital = {};
                                $scope.searchFilterEdital.txSiglaEstadoFilter = txUfUnidade;
                                $scope.searchFilterEdital.unidadeFilter = idUnidade;
                                $cookies.put('searchFilterEdital', JSON.stringify($scope.searchFilterEdital), expiresValue);
                                $cookies.remove("searchFilter");
                                $window.location.href = appConfig.baseUrl + '/beneficio/index';
                            }
                        }

                        var columnDefs = [
                            {
                                field: 'tx_sigla_uf', displayName: 'Estado', minWidth: 90, width: '**',
                            },
                            {
                                field: 'tx_nome', displayName: 'Instituição Educacional', minWidth: 170,  width: '**',
                            },
                            {
                                field: 'tipoEducacao', displayName: 'Serviço Educacional', minWidth: 170,  width: '**',
                            },
                            {
                                field: 'anoComplemento', displayName: 'Edital', minWidth: 170,  width: '**',
                            },
                            {
                                field: 'tipoEdital', displayName: 'Tipo de Edital', minWidth: 150,  width: '**',
                            },
                            // {
                            //     field: 'anexo',
                            //     cellTemplate:'<div>' +
                            //     '  <a target="_blank" href="{{row.entity.anexo}}">{{row.entity.tx_nome_anexo}}</a>' +
                            //     '</div>',
                            //     displayName: 'Anexo', minWidth: 200,  width: '**', enableSorting: false,
                            // },
                        ];

                        $scope.ufUnidadeFunction = function () {
                            $scope.unidadeFilter = '';
                            $scope.unidades = [];
                            if ($scope.filterOptions.txSiglaEstadoFilter !== undefined && $scope.filterOptions.txSiglaEstadoFilter !== '') {
                                //ajax query
                                $http({
                                    method: 'GET',
                                    url: appConfig.baseUrl + '/beneficio/listaUnidadeACad/' + $scope.filterOptions.txSiglaEstadoFilter,
                                }).then(function successCallback(response) {
                                    $scope.unidades = response.data;
                                }, function errorCallback(response) {
                                    return response;
                                });
                            }
                        }

                        //Botões customizados
                        var buttonProcessoAction = '<button uib-tooltip="{{row.entity.HintNovoProcesso}}" tooltip-placement="bottom-right" class="btn btn-xs btn-primary" ' +
                            'ng-show="row.entity.CanCreateFicha;"' +
                            'ng-click="grid.appScope.novoProcesso(row.entity.id_edital,row.entity.tp_edital,row.entity.id_unidade,row.entity.tx_sigla_uf); $event.stopPropagation();">' +
                            '<span class="glyphicon glyphicon-list-alt"></span>' +
                            '</button>  ';

                        $scope.mostraGride = function () {
                            $scope.mostraNovo = true;
                            $("#divGride").css({'display': "block"});
                        }

                        $scope.gridOptions = grideService.montaGrideOptions($scope, '/beneficio/edital/', columnDefs, '/beneficio/listaEditalSearch', 15, true, buttonProcessoAction, 140);

                    }]);