Cnec.messageBox = new (function () {

    var _effect = 'blind';

    var _messageBoxCounter = 0;

    var _optionsValidation = function (options)
    {
        var optionsValid = {};
        try {
            if (typeof (options) === 'string') {
                var message = options;
                options = {"message": message};
            }

            if (typeof (options) !== 'object') {
                throw new Core.Exception('Cnec.messageBox.question(): Sem opções válidas.');
            }
            optionsValid.title = options.title || '';
            optionsValid.message = options.message || '';
            optionsValid.details = options.details || '';
            optionsValid.callback = options.callback || null;
            optionsValid.buttons = options.buttons || {"Fechar": null};
            optionsValid.type = options.type || 'information';

            return optionsValid;
        } catch (e) {
            Core.exceptionHandler(e);
        }
        return optionsValid;
    };

    var _show = function (options)
    {
        var id = sprintf('messageBox-%d', ++_messageBoxCounter);
        Core.console.debug(options, 'show messageBox');
        //body append
        var html = '<div class="modal fade in" id="' + id + '" >';
        html += '<div class="modal-dialog">';
        html += '<div class="modal-content">';
        html += '<div class="modal-header">';

        if (options.title) {
            html += '<h4>' + options.title + '</h4>';
        } else {
            html += '<h4>Mensagem</h4>';
        }
        html += '</div>';
        html += '<div class="modal-body">';
        var cssMessage = null;
        if (options.type == 'success') {
            cssMessage = 'text-success';
        } else if (options.type == 'critical') {
            cssMessage = 'text-danger';
        } else if (options.type == 'warning') {
            cssMessage = 'text-warning';
        } else if (options.type == 'information') {
            cssMessage = 'text-info';
        }
        html += '<p class="' + cssMessage + '">' + options.message + '</p>';
        if (options.details) {
            html += '<div>';
            html += options.details;
            html += '</div>';
        }
        html += '</div>';
        html += '<div class="modal-footer">';
        /*for (var label in options.buttons) {
            html += '<button type="button" class="btn btn-default closeBox" data-dismiss="modal">Fechar</button>';

        }*/
        html += '</div>';
        html += '</div>';
        html += '</div>';
        html += '</div>';

        html += '   </div>';
        html += '   </div>';

        $('body').append(html);

        var messageBox = $('#' + id);

        var closeAction = function () {
            Core.callbackHandler(options.callback);
            messageBox.modal('hide');
        };
        //button manipulation
        var buttonSet = messageBox.find('.modal-footer');
        var firstButton = null;
        for (var label in options.buttons) {
            //button
            var button = $('<button type="button" class="btn btn-default"></button>');
            button.appendTo(buttonSet);
            button.data('callback', options.buttons[ label ]);
            button.text(label);
            button.click(function() {
                Core.callbackHandler($(this).data('callback'));
                closeAction();
            });
            if (firstButton === null) {
                firstButton = button;
            }
        }

        messageBox.find('.closeBox').click(closeAction);

        $('#' + id).modal();
    };

    this.success = function (options)
    {
        options = _optionsValidation(options);
        options.type = 'success';
        _show(options);
    };

    this.information = function (options)
    {
        options = _optionsValidation(options);
        options.type = 'information';
        _show(options);
    };

    this.warning = function (options)
    {
        options = _optionsValidation(options);
        options.type = 'warning';
        _show(options);
    };

    this.critical = function (options)
    {
        options = _optionsValidation(options);
        options.type = 'critical';
        _show(options);
    };

    this.question = function (options)
    {
        options = _optionsValidation(options);
        options.type = 'question';
        _show(options);
    };
});