angular.module('app.controllers').controller('FuncionalidadeListController', ['$scope', '$http', 'grideService', function($scope, $http, grideService) {
    //definições globais da controller
    $scope.titleModal = 'Funcionalidade';
    $scope.headerModalDelete = 'Excluir Funcionalidade';
    $scope.titleModalDeleteConfirmation = 'Confirma a exclusão da funcionalidade?';
    //config gride
    var columnDefs = [{
        field: 'tx_nome',
        displayName: 'Nome',
        minWidth: '150',
        width: '*'
    }, {
        field: 'tx_nome_exibicao',
        displayName: 'Nome exibição',
        minWidth: '150',
        width: '*'
    }, {
        field: 'tx_descricao',
        displayName: 'Descrição',
        minWidth: '150',
        width: '*'
    }];
    //chamada gride
    $scope.gridOptions = grideService.montaGrideOptions($scope, '/seguranca/funcionalidade/', columnDefs, '/seguranca/listaFuncionalidade/', 15);
}]);