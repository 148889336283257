angular.module('app.controllers')
    .controller('ProdutoCategoriaListController',
        ['$scope', 'modalService', '$http', '$window', 'grideService', 'appConfig',
            function ($scope, modalService, $http, $window, grideService, appConfig) {

                $(".btn-box-tool-cnec").trigger("click");

                var filterOptionsDefault = {};
                filterOptionsDefault.estabelecimento = $window.acad.estabelecimento_id;

                //Definições Globais da Controller
                $scope.titleModal = 'Categoria';
                $scope.headerModalDelete = 'Excluir Categoria';
                $scope.titleModalDeleteConfirmation = 'Confirma a Exclusão da Categoria?';

                var columnDefs = [
                    {field: 'id', displayName: '#', minWidth: 50, width: 50},
                    {field: 'ordem', displayName: 'Ordem', minWidth: 100, width: 100},
                    {field: 'nome', displayName: 'Nome', minWidth: 200, width: '*'},
                    {field: 'produto_categoria_pai_nome', displayName: 'Categoria Pai', minWidth: 150, width: '*'},
                ];

                $scope.gridOptions = grideService.montaGrideOptions($scope, '/administrativo/produto-categoria/', columnDefs, '/administrativo/produto-categoria/lista/json', 15, true, null, 140, filterOptionsDefault);

            }]);
