angular.module('app.controllers')
        .controller('CurriculoPlenoListController',
                ['$scope', 'modalService', '$http', '$window', 'grideService', 'appConfig', function ($scope, modalService, $http, $window, grideService, appConfig) {

                        //Definições Globais da Controller
                        $scope.titleModal = 'Disciplina';
                        $scope.headerModalDelete = 'Excluir Disciplina';
                        $scope.titleModalDeleteConfirmation = 'Confirma a exclusão da disciplina?';

                        var filterOptionsDefault = {};
                        filterOptionsDefault.curriculo = $window.acad.curriculo_id;

                        var columnDefs = [
                            {field: 'nr_semestre', displayName: 'Semestre', minWidth: 50, width: 90},
                            {field: 'disciplina_tx_nome', displayName: 'Disciplina', minWidth: 300, width: '*'},
                            {field: 'nr_carga_horaria', displayName: 'C.H', minWidth: 50, width: '*'},
                            {field: 'nr_creditos_academicos', displayName: 'Créditos', minWidth: 50, width: '*'},
                            {field: 'curriculo_pleno_disciplina_tipo_tx_nome', displayName: 'Tipo', minWidth: 150, width: '*'}
                        ];

                        $scope.gridOptions = grideService.montaGrideOptions($scope, '/academico/curriculo-pleno/', columnDefs, '/academico/curriculo-pleno/lista/json', 15, true, null, 140, filterOptionsDefault);

                    }]);
