angular.module('app.controllers')
    .controller('ProdutoListController',
        ['$scope', 'modalService', '$http', '$window', 'grideService', 'appConfig',
            function ($scope, modalService, $http, $window, grideService, appConfig) {

                $(".btn-box-tool-cnec").trigger("click");

                var filterOptionsDefault = {};
                filterOptionsDefault.estabelecimento = $window.acad.estabelecimento_id;

                //Definições Globais da Controller
                $scope.titleModal = 'Produto';
                $scope.headerModalDelete = 'Excluir Produto';
                $scope.titleModalDeleteConfirmation = 'Confirma a Exclusão do Produto?';

                var columnDefs = [
                    {field: 'id', displayName: '#', minWidth: 50, width: 50},
                    {field: 'codigo', displayName: 'Código', width: '80'},
                    {field: 'nome', displayName: 'Nome', minWidth: 150, width: '*'},
                    {field: 'produto_categoria_nome', displayName: 'Categoria', minWidth: 150, width: '*'},
                ];

                $scope.gridOptions = grideService.montaGrideOptions($scope, '/administrativo/produto/', columnDefs, '/administrativo/produto/lista/json', 15, true, null, 140, filterOptionsDefault);

            }]);
