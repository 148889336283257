angular.module('app.controllers')
        .controller('CursoTipoListController',
                ['$scope', 'modalService', '$http', 'grideService', function ($scope, modalService, $http, grideService) {

                        //Definições Globais da Controller
                        $scope.titleModal = 'Tipo';
                        $scope.headerModalDelete = 'Excluir Tipo';
                        $scope.titleModalDeleteConfirmation = 'Confirma a exclusão do tipo?';

                        var columnDefs = [{field: 'tx_sigla', displayName: 'Sigla'}, {field: 'tx_nome', displayName: 'Nome'}];

                        $scope.gridOptions = grideService.montaGrideOptions($scope, '/academico/curso-tipo/', columnDefs, '/academico/curso-tipo/lista/json', 15);

                    }]);
