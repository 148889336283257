angular.module('app.controllers')
    .controller('PeriodoListController',
        ['$scope', 'modalService', '$http', 'grideService', function ($scope, modalService, $http, grideService) {

            //Definições Globais da Controller
            $scope.titleModal = 'Período';
            $scope.headerModalDelete = 'Excluir Período';
            $scope.titleModalDeleteConfirmation = 'Confirma a exclusão do período?';

            var columnDefs = [{field: 'tx_sigla', displayName: 'Sigla'}, {field: 'tx_nome', displayName: 'Nome'}];

            $scope.gridOptions = grideService.montaGrideOptions($scope, '/academico/periodo/', columnDefs, '/academico/periodo/lista/json', 15);

        }]);
